import { useEffect, useState } from 'react';
import {
  styled,
  Stack,
  Typography,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { BrandfetchLogo } from 'shared/types';
import { getBorderRadius } from 'shared/utils';

interface Props {
  brandfetchLogos: BrandfetchLogo[];
  logoURI: string;
  setLogoURI: (uri: string) => void;
}

interface MappedLogos {
  [key: string]: string;
}

const BrandfetchLogoGroup = ({
  brandfetchLogos,
  logoURI,
  setLogoURI,
}: Props) => {
  const [simplifiedLogos, setSimplifiedLogos] = useState<string[]>();
  const theme = useTheme();

  useEffect(() => {
    if (brandfetchLogos) {
      const formatOptions = ['svg', 'png', 'jpeg'];

      const simplifiedLogos = brandfetchLogos
        .map(({ formats }) => {
          return formats.reduce((acc, { format, src }) => {
            if (src && formatOptions.includes(format)) {
              acc[format] = src;
            }

            return acc;
          }, {} as MappedLogos);
        })
        .map((obj) => {
          if (obj.svg) {
            return obj.png;
          } else if (obj.png) {
            return obj.png;
          }

          return obj.jpeg;
        });

      setSimplifiedLogos(simplifiedLogos);

      if (!logoURI) setLogoURI(simplifiedLogos[0]);
    }
  }, [brandfetchLogos]);

  return (
    <Stack>
      <Typography variant="label" mb="12px" color={theme.palette.text.darkGrey}>
        Choose a logo:
      </Typography>
      <LogoGroup
        exclusive
        fullWidth
        value={logoURI}
        onChange={(_event, newValue) => setLogoURI(newValue)}
      >
        {simplifiedLogos &&
          simplifiedLogos.map((logoSrc, index) => {
            return (
              <LogoContainer
                nonZeroIndex={index + 1}
                numberOfLogos={simplifiedLogos.length}
                disableRipple
                key={`logo-${index}`}
                value={logoSrc}
              >
                <LogoPreview src={logoSrc} alt="logo" />
              </LogoContainer>
            );
          })}
      </LogoGroup>
    </Stack>
  );
};

export default BrandfetchLogoGroup;

const LogoGroup = styled(ToggleButtonGroup)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

const LogoContainer = styled(ToggleButton, {
  shouldForwardProp: (prop) =>
    prop !== 'numberOfLogos' && prop !== 'nonZeroIndex',
})<{ numberOfLogos: number; nonZeroIndex: number }>(
  ({ theme, numberOfLogos, nonZeroIndex }) => {
    const nthClass = `&:nth-of-type(${nonZeroIndex})`;
    const isOneOfLargestThreeNumbers = [4, 5, 6].includes(nonZeroIndex);

    return {
      height: 79,
      flexBasis: '33.333%',
      borderRadius: 0,
      border: theme.border.default,

      '&:not(:last-of-type)': {
        [nthClass]: {
          borderRadius: getBorderRadius(numberOfLogos, nonZeroIndex),
        },
        '&:nth-of-type(4)': {
          marginLeft: 0,
          borderLeft: theme.border.default,
          borderRadius: getBorderRadius(numberOfLogos, 4),
        },
      },

      [nthClass]: {
        borderRadius: getBorderRadius(numberOfLogos, nonZeroIndex),
        ...(isOneOfLargestThreeNumbers && { borderTop: 0 }),
        '&.Mui-selected': {
          border: `2px solid ${theme.palette.common.black}`,
          borderLeft: `2px solid ${theme.palette.common.black}`,
          '&:hover': {
            backgroundColor: theme.palette.background.altWhite,
          },
        },
      },
    };
  },
);

const LogoPreview = styled('img')(() => ({
  objectFit: 'contain',
  maxWidth: 'calc(100% - 16px)',
  maxHeight: 'calc(100% - 14px)',
  width: 'auto',
  height: 'auto',
  margin: '7px 8px',
}));
