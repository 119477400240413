export const CloseIcon: React.FC<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" {...props}>
      <g clipPath="url(#832kbyg55a)">
        <path
          d="M19.688 1.327a1.453 1.453 0 0 0-2.057 0l-7.13 7.117-7.132-7.132A1.454 1.454 0 1 0 1.313 3.37l7.13 7.131-7.13 7.131a1.455 1.455 0 0 0 2.056 2.056l7.131-7.13 7.131 7.13a1.455 1.455 0 0 0 2.057-2.056L12.556 10.5l7.132-7.131a1.462 1.462 0 0 0 0-2.042z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="832kbyg55a">
          <path fill="#fff" d="M0 0h21v21H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
