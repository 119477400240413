/// <reference path='../../types/theme.d.ts' />

import { createTheme } from '@mui/material';
import { colors } from '../colors';
import { palette, breakpoints, typography, shape } from './index';

export const theme = createTheme({
  border: {
    default: `1px solid ${colors.borderGrey}`,
    transparent: `1px solid ${colors.transparent}`,
  },
  shape,
  breakpoints,
  palette,
  typography,
});

theme.components = {
  MuiSkeleton: {
    variants: [
      {
        props: { variant: 'rounded' },
        style: {
          borderRadius: theme.shape.borderRadius[10],
        },
      },
    ],
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
          paddingRight: '0',
        },
        '.MuiAutocomplete-input': {
          '&::placeholder': {
            fontSize: 15,
            fontWeight: 400,
          },
          '&::-webkit-search-cancel-button': {
            display: 'none',
          },
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.text.white,
        borderRadius: theme.shape.borderRadius['5'],
      },
    },
  },
  MuiSelect: {
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          paddingRight: 8,
        },
      },
    ],
    styleOverrides: {
      icon: {
        right: '20px',
        top: 'auto',
        transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: '12px',
        fontWeight: 600,
        color: theme.palette.text.darkGrey,
        marginBottom: '6px',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        background: colors.transparent,
        color: theme.palette.text.grey,
        border: theme.border.default,
        borderRadius: theme.shape.borderRadius[10],
        height: '65px',
        fontSize: '18px',
        fontWeight: 600,
      },
      notchedOutline: {
        border: theme.border.transparent,
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'close' },
        style: {
          fontSize: '14px',
          padding: 0,
          minWidth: 21,
          minHeight: 21,
          '&:hover': {
            background: colors.transparent,
          },
        },
      },
      {
        props: { variant: 'underline' },
        style: {
          textDecoration: 'underline',
          fontSize: '14px',
          color: colors.purple,
          padding: '0 12px',
          '&:hover': {
            background: colors.transparent,
            textDecoration: 'underline',
          },
        },
      },
      {
        props: { variant: 'purple' },
        style: {
          background: colors.buttonPurple,
          '&:hover': {
            background: colors.buttonPurpleHover,
          },
          [theme.breakpoints.down('lg')]: {
            maxWidth: '111px',
            fontSize: 11,
          },
        },
      },
      {
        props: { variant: 'blue' },
        style: {
          background: colors.buttonBlue,
          '&:hover': {
            background: colors.buttonBlueHover,
          },
          [theme.breakpoints.down('lg')]: {
            maxWidth: '111px',
            fontSize: 11,
          },
        },
      },
      {
        props: { variant: 'pink' },
        style: {
          padding: '6px 18px',
          background: colors.pink,
          '&:hover': {
            background: colors.buttonPinkHover,
          },
        },
      },
      {
        props: { variant: 'black' },
        style: {
          padding: '6px 18px',
          background: colors.buttonBlack,
          '&:hover': {
            background: colors.buttonBlackHover,
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        fontWeight: '600',
        textTransform: 'none',
        borderRadius: '5px',
        color: colors.white,
        minHeight: '46px',
        cursor: 'pointer',
        fontSize: '14px',
        [theme.breakpoints.down('lg')]: {
          ...theme.typography.mobileTextHeavy,
          minHeight: 37,
        },
      },
      text: {
        fontSize: '14px',
        color: colors.purple,
        minHeight: '20px',
        justifyContent: 'flex-start',
        padding: '0 8px',
      },
      [theme.breakpoints.down('lg')]: {
        ...theme.typography.mobileTextHeavy,
        minHeight: 37,
        fontSize: '11px',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
        color: colors.white,
        fontSize: '10px',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          '&::placeholder': {
            fontSize: 15,
            fontWeight: 400,
          },
        },
      },
    },
  },
  MuiTypography: {
    variants: [
      {
        props: { variant: 'tutorialTitle' },
        style: {
          color: colors.tutorialBlack,
          marginBottom: '5px',
        },
      },
      {
        props: { variant: 'tutorialText' },
        style: {
          color: colors.textGrey,
        },
      },
    ],
  },
};
