import { theme } from 'shared/styles/theme/theme';
import { ImageProps } from 'shared/types/index';

const EALogo = ({ color, height }: ImageProps) => {
  return (
    <svg
      id="b"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 592.873 76.416"
    >
      <title>EmployeeAppreciator Logo</title>
      <g id="c" data-name="b">
        <g>
          <g>
            <path d="m5.506,8.833h27.65v5.445H11.314v12.767h20.329v5.445H11.314v13.734h22.931v5.445H5.506V8.833Z" />
            <path d="m41.082,22.991h5.083v4.477h.121c.121-.403.433-.908.938-1.513.504-.605,1.149-1.18,1.936-1.724s1.714-1.008,2.783-1.392c1.068-.383,2.248-.575,3.54-.575,2.138,0,3.933.444,5.385,1.331,1.452.888,2.642,2.219,3.57,3.993.928-1.774,2.239-3.105,3.933-3.993,1.694-.887,3.408-1.331,5.143-1.331,2.218,0,4.033.363,5.445,1.089,1.412.726,2.521,1.685,3.328,2.874.807,1.19,1.361,2.541,1.664,4.054.302,1.513.454,3.076.454,4.689v16.699h-5.445v-15.973c0-1.089-.071-2.137-.212-3.146-.142-1.008-.434-1.896-.877-2.662-.444-.766-1.069-1.381-1.876-1.846-.807-.463-1.875-.696-3.207-.696-2.623,0-4.498.808-5.627,2.42-1.129,1.614-1.694,3.69-1.694,6.232v15.67h-5.445v-15.005c0-1.371-.071-2.622-.212-3.751-.142-1.129-.423-2.107-.847-2.935-.423-.826-1.028-1.472-1.815-1.936-.787-.463-1.846-.696-3.177-.696-.968,0-1.906.192-2.813.575-.907.384-1.704.959-2.39,1.725-.687.767-1.231,1.745-1.634,2.935-.403,1.19-.605,2.592-.605,4.205v14.884h-5.445v-28.679Z" />
            <path d="m92.63,22.991h5.445v4.114h.121c1.048-1.533,2.49-2.723,4.326-3.57,1.835-.847,3.721-1.271,5.657-1.271,2.218,0,4.225.384,6.021,1.149,1.794.767,3.328,1.836,4.598,3.207,1.271,1.372,2.248,2.975,2.935,4.81.686,1.836,1.028,3.802,1.028,5.899,0,2.138-.343,4.125-1.028,5.959-.687,1.836-1.664,3.429-2.935,4.78-1.271,1.352-2.804,2.411-4.598,3.177-1.795.766-3.802,1.149-6.021,1.149-2.057,0-3.993-.434-5.808-1.301-1.815-.867-3.207-2.047-4.175-3.54h-.121v21.176h-5.445V22.991Zm14.884,4.356c-1.452,0-2.763.252-3.933.756-1.17.505-2.158,1.19-2.965,2.057-.807.868-1.432,1.917-1.875,3.146-.444,1.23-.666,2.571-.666,4.023s.221,2.793.666,4.023c.443,1.231,1.068,2.279,1.875,3.146.807.868,1.794,1.553,2.965,2.057,1.169.505,2.481.756,3.933.756s2.763-.251,3.933-.756c1.169-.504,2.157-1.189,2.964-2.057.807-.867,1.432-1.915,1.876-3.146.443-1.23.666-2.571.666-4.023s-.222-2.793-.666-4.023c-.444-1.23-1.069-2.278-1.876-3.146-.807-.867-1.795-1.552-2.964-2.057-1.17-.504-2.481-.756-3.933-.756Z" />
            <path d="m130.021,5.929h5.446v45.741h-5.446V5.929Z" />
            <path d="m142.727,37.331c0-2.097.393-4.054,1.18-5.869.787-1.815,1.865-3.408,3.237-4.78s2.984-2.45,4.84-3.237c1.854-.787,3.851-1.18,5.99-1.18s4.134.393,5.99,1.18c1.855.787,3.469,1.866,4.84,3.237,1.371,1.372,2.451,2.965,3.237,4.78.786,1.815,1.18,3.772,1.18,5.869s-.394,4.064-1.18,5.899c-.787,1.836-1.866,3.429-3.237,4.78-1.372,1.352-2.985,2.42-4.84,3.207-1.855.787-3.852,1.18-5.99,1.18s-4.135-.393-5.99-1.18c-1.856-.787-3.47-1.855-4.84-3.207-1.372-1.351-2.451-2.944-3.237-4.78-.787-1.835-1.18-3.801-1.18-5.899Zm5.809,0c0,1.452.221,2.793.666,4.023.443,1.231,1.068,2.279,1.875,3.146.806.868,1.794,1.553,2.965,2.057,1.169.505,2.48.756,3.933.756s2.762-.251,3.933-.756c1.169-.504,2.157-1.189,2.965-2.057.806-.867,1.431-1.915,1.875-3.146.443-1.23.666-2.571.666-4.023s-.222-2.793-.666-4.023c-.444-1.23-1.069-2.278-1.875-3.146-.808-.867-1.795-1.552-2.965-2.057-1.17-.504-2.48-.756-3.933-.756s-2.763.252-3.933.756c-1.17.505-2.159,1.19-2.965,2.057-.808.868-1.432,1.917-1.875,3.146-.444,1.23-.666,2.571-.666,4.023Z" />
            <path d="m176.669,22.991h6.292l8.492,22.386h.122l8.146-22.386h5.808l-13.613,34.85c-.482,1.25-.985,2.39-1.507,3.418-.522,1.029-1.145,1.906-1.867,2.632-.723.726-1.597,1.291-2.622,1.694-1.025.403-2.279.605-3.766.605-.803,0-1.617-.051-2.441-.151-.823-.101-1.617-.313-2.379-.635l.662-4.961c1.082.443,2.164.666,3.247.666.841,0,1.552-.111,2.134-.333.581-.222,1.082-.544,1.503-.968.421-.423.771-.918,1.052-1.482.28-.565.561-1.21.841-1.936l1.763-4.538-11.867-28.86Z" />
            <path d="m214.424,39.327c0,1.25.272,2.39.817,3.418.544,1.028,1.26,1.906,2.148,2.632.887.726,1.915,1.292,3.085,1.694,1.169.403,2.38.605,3.63.605,1.694,0,3.166-.394,4.417-1.18,1.25-.786,2.399-1.824,3.449-3.116l4.114,3.146c-3.025,3.913-7.261,5.869-12.706,5.869-2.259,0-4.306-.384-6.141-1.149-1.836-.766-3.388-1.825-4.659-3.177-1.271-1.351-2.249-2.944-2.935-4.78-.687-1.835-1.029-3.821-1.029-5.959s.373-4.124,1.12-5.96c.746-1.835,1.774-3.428,3.085-4.78,1.311-1.351,2.874-2.41,4.689-3.176,1.815-.766,3.791-1.149,5.929-1.149,2.541,0,4.689.444,6.444,1.331,1.754.888,3.196,2.047,4.326,3.479,1.129,1.432,1.946,3.046,2.451,4.84.504,1.795.756,3.621.756,5.476v1.936h-22.992Zm17.183-4.356c-.041-1.21-.232-2.319-.575-3.328-.343-1.008-.857-1.885-1.543-2.632-.686-.746-1.543-1.331-2.571-1.755-1.029-.423-2.229-.635-3.6-.635-1.331,0-2.551.252-3.661.756-1.11.505-2.047,1.16-2.813,1.966-.767.808-1.361,1.705-1.785,2.692-.423.989-.635,1.967-.635,2.935h17.183Z" />
            <path d="m248.064,39.327c0,1.25.272,2.39.817,3.418.544,1.028,1.26,1.906,2.148,2.632.887.726,1.915,1.292,3.085,1.694,1.169.403,2.38.605,3.63.605,1.694,0,3.166-.394,4.417-1.18,1.25-.786,2.399-1.824,3.449-3.116l4.114,3.146c-3.025,3.913-7.261,5.869-12.706,5.869-2.259,0-4.306-.384-6.141-1.149-1.836-.766-3.388-1.825-4.659-3.177-1.271-1.351-2.249-2.944-2.935-4.78-.687-1.835-1.029-3.821-1.029-5.959s.373-4.124,1.12-5.96c.746-1.835,1.774-3.428,3.085-4.78,1.311-1.351,2.874-2.41,4.689-3.176,1.815-.766,3.791-1.149,5.929-1.149,2.541,0,4.689.444,6.444,1.331,1.754.888,3.196,2.047,4.326,3.479,1.129,1.432,1.946,3.046,2.451,4.84.504,1.795.756,3.621.756,5.476v1.936h-22.992Zm17.183-4.356c-.041-1.21-.232-2.319-.575-3.328-.343-1.008-.857-1.885-1.543-2.632-.686-.746-1.543-1.331-2.571-1.755-1.029-.423-2.229-.635-3.6-.635-1.331,0-2.551.252-3.661.756-1.11.505-2.047,1.16-2.813,1.966-.767.808-1.361,1.705-1.785,2.692-.423.989-.635,1.967-.635,2.935h17.183Z" />
            <path d="m292.474,8.833h4.114l18.03,42.837h-4.477l-4.659-11.375h-22.689l-4.84,11.375h-4.175l18.695-42.837Zm1.876,4.356l-9.983,23.476h19.664l-9.681-23.476Z" />
            <path d="m318.975,23.717h3.63v4.9h.121c.564-.968,1.271-1.805,2.118-2.511.847-.705,1.764-1.291,2.753-1.754.988-.463,2.027-.807,3.116-1.029,1.089-.221,2.157-.333,3.207-.333,2.138,0,4.104.363,5.899,1.089,1.794.726,3.348,1.745,4.659,3.056,1.311,1.311,2.33,2.864,3.056,4.659.726,1.795,1.089,3.762,1.089,5.899s-.363,4.105-1.089,5.899c-.726,1.795-1.745,3.348-3.056,4.659s-2.864,2.33-4.659,3.056c-1.795.726-3.762,1.089-5.899,1.089-1.049,0-2.117-.112-3.207-.333-1.089-.222-2.128-.564-3.116-1.028-.989-.463-1.906-1.048-2.753-1.755-.847-.705-1.553-1.542-2.118-2.511h-.121v18.696h-3.63V23.717Zm3.267,13.976c0,1.573.303,3.056.908,4.447s1.431,2.602,2.48,3.63c1.049,1.028,2.279,1.845,3.691,2.45,1.412.605,2.944.908,4.598.908,1.694,0,3.227-.303,4.599-.908,1.371-.605,2.541-1.422,3.509-2.45.968-1.029,1.704-2.239,2.208-3.63.503-1.392.756-2.874.756-4.447s-.252-3.055-.756-4.447c-.505-1.392-1.241-2.602-2.208-3.63-.968-1.029-2.139-1.845-3.509-2.45-1.372-.605-2.904-.908-4.599-.908-1.654,0-3.187.302-4.598.908-1.412.605-2.642,1.421-3.691,2.45-1.049,1.028-1.875,2.239-2.48,3.63s-.908,2.874-.908,4.447Z" />
            <path d="m355.942,23.717h3.63v4.9h.121c.564-.968,1.271-1.805,2.118-2.511.847-.705,1.764-1.291,2.753-1.754.988-.463,2.027-.807,3.116-1.029,1.089-.221,2.157-.333,3.207-.333,2.138,0,4.104.363,5.899,1.089,1.794.726,3.348,1.745,4.659,3.056,1.311,1.311,2.33,2.864,3.056,4.659.726,1.795,1.089,3.762,1.089,5.899s-.363,4.105-1.089,5.899c-.726,1.795-1.745,3.348-3.056,4.659s-2.864,2.33-4.659,3.056c-1.795.726-3.762,1.089-5.899,1.089-1.049,0-2.117-.112-3.207-.333-1.089-.222-2.128-.564-3.116-1.028-.989-.463-1.906-1.048-2.753-1.755-.847-.705-1.553-1.542-2.118-2.511h-.121v18.696h-3.63V23.717Zm3.267,13.976c0,1.573.303,3.056.908,4.447s1.431,2.602,2.48,3.63c1.049,1.028,2.279,1.845,3.691,2.45,1.412.605,2.944.908,4.598.908,1.694,0,3.227-.303,4.599-.908,1.371-.605,2.541-1.422,3.509-2.45.968-1.029,1.704-2.239,2.208-3.63.503-1.392.756-2.874.756-4.447s-.252-3.055-.756-4.447c-.505-1.392-1.241-2.602-2.208-3.63-.968-1.029-2.139-1.845-3.509-2.45-1.372-.605-2.904-.908-4.599-.908-1.654,0-3.187.302-4.598.908-1.412.605-2.642,1.421-3.691,2.45-1.049,1.028-1.875,2.239-2.48,3.63s-.908,2.874-.908,4.447Z" />
            <path d="m393.696,31.583c0-.524-.021-1.159-.061-1.906-.041-.746-.071-1.502-.091-2.269-.021-.766-.051-1.482-.091-2.148-.041-.666-.061-1.18-.061-1.542h3.63c.04,1.049.07,2.057.091,3.025.02.968.07,1.594.151,1.875.927-1.653,2.118-3.004,3.57-4.054,1.452-1.048,3.227-1.573,5.324-1.573.363,0,.716.03,1.059.09.342.061.696.132,1.059.212l-.424,3.57c-.484-.161-.948-.242-1.392-.242-1.573,0-2.934.252-4.084.756-1.149.505-2.098,1.201-2.843,2.087-.747.888-1.301,1.927-1.664,3.116-.363,1.19-.544,2.471-.544,3.842v15.247h-3.63v-20.087Z" />
            <path d="m437.441,46.406c-1.412,2.138-3.157,3.671-5.233,4.598-2.078.927-4.367,1.392-6.867,1.392-2.139,0-4.075-.363-5.809-1.089-1.735-.726-3.228-1.745-4.477-3.056-1.251-1.311-2.208-2.864-2.874-4.659-.666-1.794-.999-3.782-.999-5.96,0-2.097.342-4.033,1.029-5.808.685-1.774,1.653-3.317,2.904-4.629,1.25-1.31,2.723-2.339,4.417-3.085,1.694-.746,3.57-1.119,5.627-1.119,2.138,0,4.023.373,5.657,1.119,1.633.747,3.004,1.735,4.114,2.965,1.109,1.23,1.945,2.632,2.511,4.205.564,1.573.847,3.167.847,4.78v2.541h-23.476c0,.767.151,1.745.454,2.935.303,1.19.856,2.35,1.664,3.479.806,1.129,1.885,2.098,3.237,2.904,1.351.808,3.095,1.21,5.234,1.21,1.854,0,3.65-.443,5.385-1.331,1.734-.887,3.065-2.097,3.993-3.63l2.662,2.239Zm-2.783-11.072c0-1.25-.252-2.42-.756-3.509-.505-1.089-1.19-2.047-2.057-2.874-.868-.826-1.896-1.482-3.085-1.966-1.19-.484-2.451-.726-3.782-.726-1.977,0-3.621.404-4.931,1.21-1.311.808-2.35,1.735-3.116,2.783-.767,1.049-1.311,2.067-1.634,3.056-.323.989-.484,1.664-.484,2.027h19.845Z" />
            <path d="m469.811,47.556c-1.372,1.654-2.925,2.874-4.659,3.661-1.735.787-3.61,1.18-5.627,1.18-2.139,0-4.114-.363-5.929-1.089-1.815-.726-3.369-1.745-4.659-3.056-1.291-1.311-2.299-2.864-3.025-4.659-.726-1.794-1.089-3.761-1.089-5.899s.363-4.104,1.089-5.899c.726-1.794,1.734-3.348,3.025-4.659,1.291-1.311,2.844-2.33,4.659-3.056,1.815-.726,3.791-1.089,5.929-1.089,2.057,0,3.963.423,5.718,1.271,1.754.847,3.317,2.037,4.689,3.57l-2.904,2.178c-1.049-1.169-2.199-2.087-3.449-2.753-1.25-.666-2.602-.998-4.054-.998-1.694,0-3.228.302-4.598.908-1.372.605-2.542,1.421-3.509,2.45-.968,1.028-1.705,2.239-2.208,3.63-.505,1.392-.756,2.874-.756,4.447s.251,3.056.756,4.447c.504,1.392,1.24,2.602,2.208,3.63.968,1.028,2.137,1.845,3.509,2.45,1.371.605,2.904.908,4.598.908,1.532,0,2.935-.353,4.205-1.059,1.271-.705,2.369-1.643,3.297-2.813l2.783,2.299Z" />
            <path d="m475.194,12.645c0-.766.262-1.412.787-1.937.524-.523,1.169-.786,1.936-.786s1.412.263,1.937.786c.523.525.787,1.17.787,1.937s-.263,1.412-.787,1.936c-.525.524-1.17.787-1.937.787s-1.412-.262-1.936-.787c-.524-.524-.787-1.169-.787-1.936Zm.908,11.072h3.63v27.953h-3.63v-27.953Z" />
            <path d="m535.153,26.984h-7.986v18.211c0,1.251.342,2.219,1.028,2.904.686.687,1.634,1.029,2.844,1.029.686,0,1.381-.091,2.087-.272.705-.182,1.381-.413,2.027-.696l.303,3.267c-.687.242-1.473.463-2.36.666-.888.202-1.674.303-2.359.303-1.292,0-2.4-.192-3.328-.575-.928-.383-1.674-.897-2.238-1.543-.565-.645-.979-1.401-1.241-2.269-.263-.867-.393-1.805-.393-2.813v-18.211h-6.05v-3.267h6.05v-8.047h3.63v8.047h7.986v3.267Z" />
            <path d="m540.053,37.693c0-2.137.363-4.104,1.089-5.899.726-1.794,1.734-3.348,3.025-4.659,1.291-1.311,2.844-2.33,4.659-3.056,1.815-.726,3.791-1.089,5.929-1.089s4.104.363,5.899,1.089c1.794.726,3.348,1.745,4.659,3.056,1.31,1.311,2.329,2.864,3.055,4.659.726,1.795,1.089,3.762,1.089,5.899s-.363,4.105-1.089,5.899c-.726,1.795-1.745,3.348-3.055,4.659-1.312,1.311-2.865,2.33-4.659,3.056-1.795.726-3.761,1.089-5.899,1.089s-4.114-.363-5.929-1.089c-1.815-.726-3.369-1.745-4.659-3.056-1.291-1.311-2.299-2.864-3.025-4.659-.726-1.794-1.089-3.761-1.089-5.899Zm3.63,0c0,1.573.251,3.056.756,4.447.504,1.392,1.24,2.602,2.208,3.63.968,1.028,2.137,1.845,3.509,2.45,1.371.605,2.904.908,4.598.908s3.227-.303,4.598-.908c1.371-.605,2.542-1.422,3.509-2.45.968-1.029,1.704-2.239,2.208-3.63.504-1.392.756-2.874.756-4.447s-.252-3.055-.756-4.447c-.505-1.392-1.24-2.602-2.208-3.63-.968-1.029-2.138-1.845-3.509-2.45-1.372-.605-2.904-.908-4.598-.908s-3.228.302-4.598.908c-1.372.605-2.542,1.421-3.509,2.45-.968,1.028-1.705,2.239-2.208,3.63-.505,1.392-.756,2.874-.756,4.447Z" />
            <path d="m577.565,31.583c0-.524-.021-1.159-.061-1.906-.041-.746-.071-1.502-.091-2.269-.021-.766-.051-1.482-.091-2.148-.041-.666-.061-1.18-.061-1.542h3.63c.04,1.049.07,2.057.091,3.025.02.968.07,1.594.151,1.875.927-1.653,2.118-3.004,3.57-4.054,1.452-1.048,3.227-1.573,5.324-1.573.363,0,.716.03,1.059.09.342.061.696.132,1.059.212l-.424,3.57c-.484-.161-.948-.242-1.392-.242-1.573,0-2.934.252-4.084.756-1.149.505-2.098,1.201-2.843,2.087-.747.888-1.301,1.927-1.664,3.116-.363,1.19-.544,2.471-.544,3.842v15.247h-3.63v-20.087Z" />
          </g>
          <path
            d="m514.118,49.783v-.7s0-.1-.1-.1h0l-11.5-23.6v-.1s0-.1-.1-.1v-.1s0-.1-.1-.1c0,0,0-.1-.1-.1,0,0,0-.1-.1-.1l-.1-.1-.1-.1c-.1-.1-.1,0-.1-.1,0,0-.1,0-.1-.1,0,0-.1,0-.1-.1,0,0-.1,0-.1-.1,0,0-.1,0-.1-.1h-2s-.1,0-.1.1h-.1s-.1,0-.1.1c0,0-.1,0-.1.1,0,0-.1,0-.1.1l-.1.1-.1.1s0,.1-.1.1c0,0,0,.1-.1.1,0,0,0,.1-.1.1v.1s0,.1-.1.1v.1l-11.4,23.5c-.5,1.1-.1,2.3,1,2.9.3.1.6.2.9.2.8,0,1.6-.4,1.9-1.2l9.4-19.4h0l.1-.1,8,16.5h0v.1-.1h-10.2c-1.2,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1h14.2q.1,0,.2-.1h.1s.1,0,.1-.1h.1s.1,0,.1-.1c0,0,.1,0,.1-.1l.1-.1.1-.1.1-.1.1-.1.1-.1s0-.1.1-.1c0,0,0-.1.1-.1,0,0,0-.1.1-.1,0,0,0-.1.1-.1v-.8q.3.2.3,0,0,.1,0,0c0,.1,0,0,0,0h0Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

EALogo.defaultProps = {
  color: theme.palette.common.black,
  height: 24,
};

export default EALogo;
