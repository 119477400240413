export const CaretDownIcon = ({ ...props }) => {
  return (
    <svg width="20" height="11" viewBox="0 0 20 11" {...props}>
      <path
        d="M0.404486 0.374999C0.656683 0.124999 0.955284 -7.89165e-07 1.30029 -7.74216e-07C1.64462 -7.59296e-07 1.94289 0.124999 2.19509 0.374999L9.58447 7.7L16.9991 0.35C17.2345 0.116666 17.5287 -7.1031e-08 17.8818 -5.5732e-08C18.2348 -4.0433e-08 18.5375 0.125 18.7897 0.375C19.0419 0.625 19.168 0.921 19.168 1.263C19.168 1.60433 19.0419 1.9 18.7897 2.15L10.2906 10.55C10.1897 10.65 10.0805 10.721 9.96277 10.763C9.84507 10.8043 9.71897 10.825 9.58447 10.825C9.44996 10.825 9.32386 10.8043 9.20617 10.763C9.08848 10.721 8.9792 10.65 8.87832 10.55L0.379267 2.125C0.143881 1.89167 0.0261897 1.60433 0.0261897 1.263C0.0261898 0.920999 0.152288 0.624999 0.404486 0.374999Z"
        fill="black"
      />
    </svg>
  );
};
