import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { CaretDownIcon } from 'static/icons';
import { styled } from '@mui/material';
import { colors } from 'shared/styles/colors';
import SelectCommunicationGroup, {
  SelectCommunicationProps,
} from './SelectCommunicationGroup';
import EmployeeDetailsGroup, {
  EmployeeDetailsProps,
} from './EmployeeDetailsGroup';
import BrandFetchGroup, { BrandFetchProps } from './BrandFetchGroup';

export interface AccordionProps
  extends SelectCommunicationProps,
    EmployeeDetailsProps,
    BrandFetchProps {}

const MobileAccordion = ({
  eventType,
  eventTypes,
  setImageStyle,
  setTextTone,
  imageStyle,
  textTone,
  handleEventChange,
  setEmployeeImageURI,
  employeeImageURI,
  employeeName,
  setEmployeeName,
  isEntryReady,
  // BrandFetch Props
  brandRouteOption,
  setBrandRouteOption,
  setBrandfetchValues,
  brandfetchValues,
  setAccentColor,
  logoURI,
  accentColor,
  setLogoURI,
  variantRange,
}: AccordionProps) => {
  return (
    <>
      <StyledAccordion>
        <StyledSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordionLabel>1. Select Communication:</AccordionLabel>
        </StyledSummary>
        <StyledDetails>
          <SelectCommunicationGroup
            eventType={eventType}
            eventTypes={eventTypes}
            setImageStyle={setImageStyle}
            setTextTone={setTextTone}
            imageStyle={imageStyle}
            textTone={textTone}
            handleEventChange={handleEventChange}
            variantRange={variantRange}
            isEntryReady={isEntryReady}
          />
        </StyledDetails>
      </StyledAccordion>
      <Line />

      <StyledAccordion>
        <StyledSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <AccordionLabel>2. Enter Employee Details</AccordionLabel>
        </StyledSummary>
        <StyledDetails>
          <EmployeeDetailsGroup
            setEmployeeImageURI={setEmployeeImageURI}
            employeeImageURI={employeeImageURI}
            employeeName={employeeName}
            setEmployeeName={setEmployeeName}
          />
        </StyledDetails>
      </StyledAccordion>
      <Line />

      <StyledAccordion>
        <StyledSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <AccordionLabel>3. Apply Your Brand Kit</AccordionLabel>
        </StyledSummary>
        <StyledDetails>
          <BrandFetchGroup
            brandRouteOption={brandRouteOption}
            setBrandRouteOption={setBrandRouteOption}
            setBrandfetchValues={setBrandfetchValues}
            brandfetchValues={brandfetchValues}
            setAccentColor={setAccentColor}
            logoURI={logoURI}
            accentColor={accentColor}
            setLogoURI={setLogoURI}
          />
        </StyledDetails>
      </StyledAccordion>
      <Line />
    </>
  );
};

export default MobileAccordion;

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  border: 'none',
});

const Line = styled('div')({
  width: '100%',
  background: colors.accentGrey,
  height: 1,
});

const AccordionLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.mobileBoldHeavy,
}));

const StyledSummary = styled(AccordionSummary)({
  padding: 0,
});

const StyledDetails = styled(AccordionDetails)({
  padding: 0,
});
