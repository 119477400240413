import {
  ImageStylePageNumbers,
  ToggleBorderRadiuses,
  PageNumbersImageStyle,
} from './types';
import { theme } from './styles/theme/theme';
import StepContent from 'shared/components/tutorial/StepContent';
import { StepType } from '@reactour/tour';
import { PositionProps } from '@reactour/popover';

export const IMAGE_STYLES = [
  { style: 'Bold', order: 1 },
  { style: 'Sophisticated', order: 2 },
  { style: 'Organic', order: 3 },
  { style: 'Formal', order: 4 },
  { style: 'Minimalist', order: 5 },
  { style: 'Bold (No Profile Photo)', order: 6 },
  { style: 'Sophisticated (No Profile Photo)', order: 7 },
  { style: 'Organic (No Profile Photo)', order: 8 },
  { style: 'Formal (No Profile Photo)', order: 9 },
  { style: 'Minimalist (No Profile Photo)', order: 10 },  
  { style: 'Fun', order: 11 },
  { style: 'Elegant', order: 12 },
  { style: 'Rustic', order: 13 },
  { style: 'Corporate', order: 14 },
  { style: 'Simple', order: 15 },
  { style: 'Playful', order: 16 },
  { style: 'Chic', order: 17 },
  { style: 'Earthy', order: 18 },
  { style: 'Executive', order: 19 },
  { style: 'Clean', order: 20 },
  { style: 'Artful', order: 21 },
  { style: 'Upscale', order: 22 },
  { style: 'Botanical', order: 23 },
  { style: 'Business', order: 24 },
  { style: 'Modern', order: 25 },
];

export const TEXT_TONES = {
  professional: 'Professional',
  casual: 'Casual',
  direct: 'Direct',
};

export const IMAGE_STYLE_PAGE_NUMBERS: ImageStylePageNumbers = {
  Bold: 1,
  Sophisticated: 2,
  Organic: 3,
  Formal: 4,
  Minimalist: 5,
  'Bold (No Profile Photo)': 6,
  'Sophisticated (No Profile Photo)': 7,
  'Organic (No Profile Photo)': 8,
  'Formal (No Profile Photo)': 9,
  'Minimalist (No Profile Photo)': 10,  
  Fun: 11,
  Elegant: 12,
  Rustic: 13,
  Corporate: 14,
  Simple: 15,
  Playful: 16,
  Chic: 17,
  Earthy: 18,
  Executive: 19,
  Clean: 20,
  Artful: 21,
  Upscale: 22,
  Botanical: 23,
  Business: 24,
  Modern: 25,
};

export const PAGE_NUMBERS_IMAGE_STYLE: PageNumbersImageStyle = {
  1: 'Bold',
  2: 'Sophisticated',
  3: 'Organic',
  4: 'Formal',
  5: 'Minimalist',
  6: 'Bold (No Profile Photo)',
  7: 'Sophisticated (No Profile Photo)',
  8: 'Organic (No Profile Photo)',
  9: 'Formal (No Profile Photo)',
  10: 'Minimalist (No Profile Photo)',  
  11: 'Fun',
  12: 'Elegant',
  13: 'Rustic',
  14: 'Corporate',
  15: 'Simple',
  16: 'Playful',
  17: 'Chic',
  18: 'Earthy',
  19: 'Executive',
  20: 'Clean',
  21: 'Artful',
  22: 'Upscale',
  23: 'Botanical',
  24: 'Business',
  25: 'Modern', 
};

export const IMAGE_PREVIEW_WIDTH = 800;

export enum BrandRouteOptions {
  Brandfetch = 'brandfetch',
  Custom = 'custom',
}

export const TOGGLE_BORDER_RADIUSES: ToggleBorderRadiuses = {
  1: {
    1: theme.shape.borderRadius.full10,
  },
  2: {
    1: theme.shape.borderRadius.left10,
    2: theme.shape.borderRadius.right10,
  },
  3: {
    1: theme.shape.borderRadius.left10,
    3: theme.shape.borderRadius.right10,
  },
  4: {
    1: theme.shape.borderRadius.topLeft10,
    3: theme.shape.borderRadius.topRight10,
    4: theme.shape.borderRadius.bottomRight10,
  },
  5: {
    1: theme.shape.borderRadius.topLeft10,
    3: theme.shape.borderRadius.topRight10,
    4: theme.shape.borderRadius.bottomLeft10,
    5: theme.shape.borderRadius.bottomRight10,
  },
  6: {
    1: theme.shape.borderRadius.topLeft10,
    3: theme.shape.borderRadius.topRight10,
    4: theme.shape.borderRadius.bottomLeft10,
    6: theme.shape.borderRadius.bottomRight10,
  },
};

export const QueryParams = {
  Company: 'company',
  Color: 'color',
  ImageStyle: 'imageStyle',
  TextTone: 'textTone',
  EmployeeName: 'employeeName',
  EmployeeImage: 'employeeImage',
  EventType: 'eventType',
  LogoImage: 'logoImage',
  CompanyName: 'companyName',
  HideNav: 'hideNav',
};

export const tutorialSteps: StepType[] = [
  {
    selector: '.step-one',
    position: ({ right, top }: PositionProps) => {
      return [right + 24, top];
    },
    padding: {
      mask: [0, 8, 16, 0],
    },
    content: (
      <StepContent
        title="Step 1 - Select Communication"
        content="Choose an event, pick an image style, and set the tone of your text to create a personalized appreciation message."
      />
    ),
  },
  {
    selector: '.step-two',
    position: ({ right, top }: PositionProps) => {
      return [right + 24, top];
    },
    padding: {
      mask: [0, 0, 16, 0],
    },
    content: (
      <StepContent
        title="Step 2 - Enter Employee Details"
        content="Who's the star today? Enter their name and upload a profile picture of them. Quick tip: You can easily grab their LinkedIn photo!"
      />
    ),
  },
  {
    selector: '.step-three',
    position: ({ right, top }: PositionProps) => {
      return [right + 24, top];
    },
    padding: {
      mask: 0,
    },
    content: (
      <StepContent
        title="Step 3  - Apply Your Brand Kit"
        content="Add your brand's touch. Enter your website URL for automatic color and logo fetching,or input manually."
      />
    ),
  },
  {
    selector: '.step-four',
    position: ({ left, bottom, height, width }: PositionProps) => {
      const positionLeft = left - width - 24;
      const positionBottom = bottom - height - 82;
      return [positionLeft, positionBottom];
    },
    padding: {
      mask: 0,
    },
    content: (
      <StepContent
        title="Preview and Edit"
        content="Congratulations! Your personalized appreciation is ready. Save the image and text, or select 'Edit Image' for further adjustments."
      />
    ),
  },
  {
    selector: '.step-five',
    position: 'center',
    padding: {
      mask: 0,
    },
    content: (
      <StepContent
        title="You're All Set!"
        content="Now you're ready to create impactful appreciation content in no time. Who's the first person you'd like to appreciate today?"
      />
    ),
  },
];
