import { ThemeProvider, CssBaseline, GlobalStyles } from '@mui/material';
import { theme } from './shared/styles/theme/theme';
import { globalStyles } from 'shared/styles/globalStyles';
import HomePage from 'modules/HomePage/HomePage';
import { SnackbarProvider } from 'notistack';
import { Route, Routes } from 'react-router-dom';
import { TourProvider } from '@reactour/tour';
import { tutorialSteps } from 'shared/constants';
import PopoverContent from 'shared/components/tutorial/PopoverContent';
import { popoverStyles } from 'shared/styles/tutorialStyles';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

function App() {
  const disableBody = (target: Element) => disableBodyScroll(target);
  const enableBody = (target: Element) => enableBodyScroll(target);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      <TourProvider
        steps={tutorialSteps}
        showBadge={false}
        scrollSmooth
        ContentComponent={PopoverContent}
        afterOpen={(target) => {
          if (target) disableBody(target);
        }}
        beforeClose={(target) => {
          if (target) enableBody(target);
        }}
        styles={{
          popover: (base) => ({
            ...base,
            ...popoverStyles,
          }),
        }}
      >
        <SnackbarProvider
          preventDuplicate
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </SnackbarProvider>
      </TourProvider>
    </ThemeProvider>
  );
}

export default App;
