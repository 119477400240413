export const EditIcon = ({ ...props }) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
      <g clipPath="url(#32v741g09a)">
        <path
          d="M6.313 15.685h.92l5.663-5.671-.92-.92-5.663 5.67v.92zm9.39-6.625L12.912 6.3l.92-.92A1.26 1.26 0 0 1 14.758 5c.366 0 .675.126.927.378l.92.92c.251.253.382.557.393.913.011.356-.11.66-.36.912l-.936.937zM5.657 17a.633.633 0 0 1-.468-.19.635.635 0 0 1-.189-.467v-1.858a.678.678 0 0 1 .197-.477l6.763-6.772 2.79 2.794-6.762 6.773A.657.657 0 0 1 7.51 17H5.657zm6.779-7.447-.46-.46.92.92-.46-.46z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="32v741g09a">
          <path fill="#fff" d="M0 0h21v21H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
