import { useTour, components } from '@reactour/tour';
import { Box, styled, Button, IconButton, Stack } from '@mui/material';
import { PopoverContentProps } from '@reactour/tour';
import { CloseIcon, ArrowLeftIcon, ArrowRightIcon } from 'static/icons';
import { tutorialSteps } from 'shared/constants';
import { dotStyles, controlStyles } from 'shared/styles/tutorialStyles';

export default function PopoverContent({
  steps,
  currentStep,
  setCurrentStep,
}: PopoverContentProps) {
  const { setIsOpen } = useTour();

  const isLastStep = currentStep === steps.length - 1;
  const content = steps[currentStep].content;

  return (
    <Box>
      <CloseButton variant="close" onClick={() => setIsOpen(false)}>
        <CloseIcon />
      </CloseButton>
      <components.Content
        content={content}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mt="12px"
      >
        <PrevButton
          onClick={() => {
            if (currentStep !== 0) {
              setCurrentStep((s) => s - 1);
            }
          }}
        >
          <ArrowLeftIcon />
        </PrevButton>
        <components.Navigation
          setIsOpen={setIsOpen}
          steps={tutorialSteps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          hideButtons={true}
          styles={{
            dot: (base, state) => dotStyles(base, state),
            controls: (base) => ({
              ...base,
              ...controlStyles,
            }),
          }}
        />
        <NextButton
          isLastStep={isLastStep}
          onClick={() => {
            if (isLastStep) {
              setIsOpen(false);
            } else {
              setCurrentStep((s) => s + 1);
            }
          }}
        >
          {isLastStep ? 'Done' : <ArrowRightIcon />}
        </NextButton>
      </Stack>
    </Box>
  );
}

const CloseButton = styled(Button)(({ theme }) => ({
  width: 15,
  minWidth: 'none',
  height: 15,
  padding: 0,
  right: 12,
  top: 14,
  position: 'absolute',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  zIndex: theme.zIndex.modal,

  '& svg': {
    width: 15,
    height: 15,
  },
}));

const PrevButton = styled(IconButton)(({ theme }) => ({
  height: 28,
  padding: '0 7px',
  border: 'none',
  zIndex: theme.zIndex.modal,
  borderRadius: theme.shape.borderRadius['5'],
  background: theme.palette.tutorialNavigation.buttonPrev,

  '&:hover': {
    background: theme.palette.tutorialNavigation.buttonPrev,
  },
}));

const NextButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isLastStep',
})<{ isLastStep: boolean }>(({ theme, isLastStep }) => ({
  height: 28,
  padding: isLastStep ? '0 13px' : '0 7px',
  background: theme.palette.tutorialNavigation.buttonNext,
  border: 'none',
  zIndex: theme.zIndex.modal,
  borderRadius: theme.shape.borderRadius['5'],
  color: theme.palette.common.white,
  ...theme.typography.mobileText,

  '&:hover': {
    background: theme.palette.common.black,
  },
}));
