import { Stack } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import Input from 'shared/components/Input';
import PhotoUpload from 'shared/components/PhotoUpload';

export interface EmployeeDetailsProps {
  setEmployeeName: Dispatch<SetStateAction<string>>;
  setEmployeeImageURI: Dispatch<SetStateAction<string>>;
  employeeName: string;
  employeeImageURI: string;
}

const EmployeeDetailsGroup = ({
  setEmployeeName,
  employeeName,
  employeeImageURI,
  setEmployeeImageURI,
}: EmployeeDetailsProps) => {
  return (
    <Stack spacing="14px">
      <Input
        handleChange={(value) => setEmployeeName(value)}
        label="Employee Name"
        value={employeeName}
        placeholder="Enter your employee's name"
      />
      <PhotoUpload
        label="Employee Profile Photo:"
        parenthesesText="File should be .jpg or .png"
        imageURI={employeeImageURI}
        setImageURI={(uri: string) => setEmployeeImageURI(uri)}
        inputId="employee-photo"
      />
    </Stack>
  );
};

export default EmployeeDetailsGroup;
