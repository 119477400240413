import { useEffect, useState } from 'react';
import {
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
  Typography,
} from '@mui/material';
import {StickyShareButtons} from 'sharethis-reactjs';
import Footer from 'shared/components/Footer';
import Header from 'shared/components/Header';
import { Container } from 'shared/components/styledComponents';
import { fetchAirtableData } from 'services/Airtable';
import {
  IMAGE_STYLES,
  TEXT_TONES,
  BrandRouteOptions,
  QueryParams,
} from 'shared/constants';
import { AirtableEntry, BrandfetchSelectionResult } from 'shared/types';
import { DesignPreview } from 'shared/components/designPreview/DesignPreview';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { colors } from 'shared/styles/colors';
import MobileHomePage from './MobileHomePage';
import RandomButton from 'shared/components/RandomButton';
import SelectCommunicationGroup from './components/SelectCommunicationGroup';
import EmployeeDetailsGroup from './components/EmployeeDetailsGroup';
import BrandFetchGroup from './components/BrandFetchGroup';
import { parseFullName } from 'parse-full-name';
import TutorialModal from '../../shared/components/tutorial/TutorialModal';
import { useTour } from '@reactour/tour';

const HomePage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { setIsOpen, currentStep } = useTour();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  const accentColorFromParams = searchParams.get(QueryParams.Color);
  const imageStyleParams = searchParams.get(QueryParams.ImageStyle);
  const textToneParams = searchParams.get(QueryParams.TextTone);
  const employeeNameParams = searchParams.get(QueryParams.EmployeeName);
  const employeeImageParams = searchParams.get(QueryParams.EmployeeImage);
  const eventTypeParams = searchParams.get(QueryParams.EventType);
  const logoImageParams = searchParams.get(QueryParams.LogoImage);
  const companyNameParams = searchParams.get(QueryParams.CompanyName);
  const hideNavParams = searchParams.get(QueryParams.HideNav);

  const [isModalOpen, setIsModalOpen] = useState(!isMobile);
  const [airtableData, setAirtableData] = useState<AirtableEntry[]>([]);
  const [currentEntry, setCurrentEntry] = useState<AirtableEntry>();
  const [eventTypes, setEventTypes] = useState<string[]>();
  const [eventType, setEventType] = useState<string>(eventTypeParams ?? '');
  const [imageStyle, setImageStyle] = useState<string>(
    imageStyleParams ?? IMAGE_STYLES[0].style,
  );
  const [textTone, setTextTone] = useState<string>(
    textToneParams ?? TEXT_TONES.professional,
  );
  const [textContent, setTextContent] = useState('');
  const [logoURI, setLogoURI] = useState<string>(logoImageParams ?? '');
  const [accentColor, setAccentColor] = useState<string>(
    accentColorFromParams ?? '',
  );
  const [brandRouteOption, setBrandRouteOption] = useState<string>(
    BrandRouteOptions.Brandfetch,
  );
  const [brandfetchValues, setBrandfetchValues] =
    useState<BrandfetchSelectionResult>();
  const [employeeName, setEmployeeName] = useState<string>(
    employeeNameParams ?? '',
  );
  const [employeeImageURI, setEmployeeImageURI] = useState<string>(
    employeeImageParams ?? '',
  );
  const [companyName, setCompanyName] = useState<string>(
    companyNameParams ?? '',
  );

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (currentStep === 3) {
      const buttonContainer = document.querySelector(
        '[data-name="preview-button-container"]',
      );
      buttonContainer?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [currentStep]);

  const changeRoute = () => {
    const searchParams = new URLSearchParams({
      ...(accentColor ? { [QueryParams.Color]: accentColor } : {}),
      ...(eventType ? { [QueryParams.EventType]: eventType } : {}),
      ...(textTone ? { [QueryParams.TextTone]: textTone } : {}),
      ...(logoURI ? { [QueryParams.LogoImage]: logoURI } : {}),
      ...(imageStyle ? { [QueryParams.ImageStyle]: imageStyle } : {}),
      ...(brandfetchValues?.domain
        ? { [QueryParams.Company]: brandfetchValues.domain }
        : {}),
      ...(brandfetchValues?.name
        ? { [QueryParams.CompanyName]: brandfetchValues.name }
        : {}),
      ...(employeeName ? { [QueryParams.EmployeeName]: employeeName } : {}),
      ...(hideNavParams ? { [QueryParams.HideNav]: hideNavParams } : {}),
      ...(employeeImageURI
        ? { [QueryParams.EmployeeImage]: employeeImageURI }
        : {}),
    }).toString();
    navigate({
      search: searchParams,
    });
  };

  useEffect(() => {
    changeRoute();
  }, [
    accentColor,
    imageStyle,
    textTone,
    employeeName,
    brandfetchValues?.domain,
    brandfetchValues?.name,
    employeeImageURI,
    eventType,
    logoURI,
  ]);

  async function getInitialData() {
    const { typedData, eventTypes } = await fetchAirtableData();
    let defaultEntry;
    if (eventTypeParams) {
      defaultEntry = typedData.find(
        (obj) => obj['Moment Title'] === eventTypeParams,
      );
    }
    if (!defaultEntry) {
      defaultEntry = typedData[0];
    }

    setAirtableData(typedData);
    setEventTypes(eventTypes);
    setCurrentEntry(defaultEntry);
    setEventType(defaultEntry['Moment Title']);

    const textContent = defaultEntry[textTone] as string;
    setTextContent(textContent);

    if (accentColorFromParams) {
      setAccentColor(accentColorFromParams);
    } else if (defaultEntry['Color HEX'] && !accentColorFromParams) {
      const defaultEntryAccentColor = defaultEntry['Color HEX'][0];
      setAccentColor(defaultEntryAccentColor);
    } else {
      setAccentColor(colors.defaultAccentColor);
    }
  }

  const setRandomOptions = () => {
    const randomEntry =
      airtableData[(airtableData.length * Math.random()) << 0];
    const textToneKeys = Object.keys(TEXT_TONES) as Array<
      keyof typeof TEXT_TONES
    >;
    const randomImageStyle =
      IMAGE_STYLES[(randomEntry.variants * Math.random()) << 0].style;
    const randomTextTone =
      TEXT_TONES[textToneKeys[(textToneKeys.length * Math.random()) << 0]];

    setCurrentEntry(randomEntry);
    setEventType(randomEntry['Moment Title']);
    setImageStyle(randomImageStyle);
    setTextTone(randomTextTone);
  };

  const handleEventChange = (value: string) => {
    setEventType(value);

    const newEntry = airtableData.find(
      (entry) => entry['Moment Title'] === value,
    );

    const isCustomBrandingRoute = brandRouteOption === BrandRouteOptions.Custom;
    const useEntryAccentColor =
      newEntry && newEntry['Color HEX'] && isCustomBrandingRoute;

    if (useEntryAccentColor) {
      const accentColor = newEntry['Color HEX'][0];
      setAccentColor(accentColor);
    }

    setCurrentEntry(newEntry);
  };

  useEffect(() => {
    const isBrandfetchBrandingRoute =
      brandRouteOption === BrandRouteOptions.Brandfetch;

    if (brandfetchValues?.colors && isBrandfetchBrandingRoute) {
      setAccentColor(brandfetchValues?.colors[0].hex);
    }

    if (!brandfetchValues && !isBrandfetchBrandingRoute) {
      const defaultAccentColorExists =
        currentEntry && currentEntry['Color HEX'];
      const accentColor = defaultAccentColorExists
        ? currentEntry['Color HEX'][0]
        : colors.defaultAccentColor;

      setAccentColor(accentColor);
      setLogoURI('');
    }

    if (brandfetchValues?.name && isBrandfetchBrandingRoute) {
      setCompanyName(brandfetchValues.name);
    }
  }, [brandfetchValues]);

  useEffect(() => {
    if (!currentEntry) return;

    let updatedTextContent = currentEntry[textTone] as string;

    const firstName = parseFullName(employeeName).first;
    let displayName = firstName;
    if (!firstName?.length) {
      displayName = employeeName;
    }

    if (displayName && companyName) {
      updatedTextContent = updatedTextContent
        .replaceAll('{{first\\_name}}', displayName)
        .replaceAll('{{company\\_name}}', companyName);
    } else if (!displayName && companyName) {
      updatedTextContent = updatedTextContent.replaceAll(
        '{{company\\_name}}',
        companyName,
      );
    } else if (displayName && !companyName) {
      updatedTextContent = updatedTextContent.replaceAll(
        '{{first\\_name}}',
        displayName,
      );
    }

    setTextContent(updatedTextContent);
  }, [textTone, currentEntry, employeeName, companyName]);

  useEffect(() => {
    if (isMobile) {
      setIsModalOpen(false);
      setIsOpen(false);
    }
  }, [isMobile]);

  return (
    <>
    <StickyShareButtons
          config={{
            alignment: 'left',    // alignment of buttons (left, right)
            color: 'white',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            labels: 'cta',     // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            min_count: 0,         // hide react counts less than min_count (INTEGER)
            networks: [           // which networks to include (see SHARING NETWORKS)
              'linkedin',
              'whatsapp',
              'facebook',
              'email'
            ],
            padding: 12,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: false,     // show/hide the total share count (true, false)
            show_mobile: false,    // show/hide the buttons on mobile (true, false)
            show_toggle: false,    // show/hide the toggle buttons (true, false)
            size: 48,             // the size of each button (INTEGER)
            top: 160,             // offset in pixels from the top of the page

            // OPTIONAL PARAMETERS

            image: '%PUBLIC_URL%/img/EmployeeAppreciator.png',  // (defaults to og:image or twitter:image)
            description: 'Create personalized messages of employee appreciation and recognition in under 30 seconds!',       // (defaults to og:description or twitter:description)
            title: 'ChangeEngine EmployeeAppreciator',            // (defaults to og:title or twitter:title)
            message: 'Head to the Employee Appreciator to create personalized messages of employee appreciation and recognition in seconds: Employeeappreciator.com',     // (only for email sharing)
            subject: 'Employee Appreciation',  // (only for email sharing)
            username: 'changeengineHQ' // (only for twitter sharing)

          }}
        />
      <TutorialModal open={isModalOpen} onClose={setIsModalOpen} />
      <Stack direction="column" minHeight="100%">
        {isMobile ? (
          <>
            <MobileHomePage
              currentEntry={currentEntry}
              accentColor={accentColor}
              textTone={textTone}
              textContent={textContent}
              imageStyle={imageStyle}
              logoURI={logoURI}
              employeeName={employeeName}
              employeeImageURI={employeeImageURI}
              setRandomOptions={() => setRandomOptions()}
              setImageStyle={setImageStyle}
              setTextTone={setTextTone}
              handleEventChange={handleEventChange}
              eventTypes={eventTypes}
              eventType={eventType}
              setEmployeeName={setEmployeeName}
              setEmployeeImageURI={setEmployeeImageURI}
              brandRouteOption={brandRouteOption}
              setBrandRouteOption={setBrandRouteOption}
              setBrandfetchValues={setBrandfetchValues}
              brandfetchValues={brandfetchValues}
              setAccentColor={setAccentColor}
              setLogoURI={setLogoURI}
              variantRange={currentEntry?.variants ?? 5}
              hideNav={hideNavParams === 'true'}
              isEntryReady={Boolean(currentEntry)}
            />
          </>
        ) : (
          <>
            {hideNavParams === 'true' ? null : <Header />}
            <Container
              m={{
                xs: '75px auto 0',
                lg: '58px auto 62px',
                xl: '45px auto 96px',
              }}
              flexGrow={{ lg: 1 }}
            >
              <Grid container columnSpacing={{ md: 3 }}>
                <Grid item xs={5} height="100%">
                  <Stack
                    bgcolor={theme.palette.background.altWhite}
                    height="100%"
                  >
                    <Stack
                      className="step-one"
                      p={{ lg: '24px 38px 0px 40px', xl: '24px 46px 0px 40px' }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        pb="8px"
                        justifyContent="space-between"
                      >
                        <Typography variant="body2">
                          1. Select Communication:
                        </Typography>
                        <RandomButton handleClick={() => setRandomOptions()} />
                      </Stack>
                      <SelectCommunicationGroup
                        handleEventChange={handleEventChange}
                        setImageStyle={setImageStyle}
                        setTextTone={setTextTone}
                        textTone={textTone}
                        imageStyle={imageStyle}
                        eventType={eventType}
                        eventTypes={eventTypes}
                        variantRange={currentEntry?.variants ?? 5}
                        isEntryReady={Boolean(currentEntry)}
                      />
                    </Stack>
                    <Stack
                      className="step-two"
                      p={{ lg: '24px 38px 0px 40px', xl: '24px 46px 0px 40px' }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        pb="8px"
                        justifyContent="space-between"
                      >
                        <Typography variant="body2">
                          2. Enter Employee Details:
                        </Typography>
                      </Stack>
                      <EmployeeDetailsGroup
                        setEmployeeImageURI={setEmployeeImageURI}
                        employeeImageURI={employeeImageURI}
                        setEmployeeName={setEmployeeName}
                        employeeName={employeeName}
                      />
                    </Stack>
                    <Stack
                      className="step-three"
                      p={{
                        lg: '24px 38px 58px 40px',
                        xl: '24px 46px 48px 40px',
                      }}
                    >
                      <Typography variant="body2" pb="16px">
                        3. Apply Brand Kit:
                      </Typography>
                      <BrandFetchGroup
                        brandRouteOption={brandRouteOption}
                        setBrandRouteOption={setBrandRouteOption}
                        setBrandfetchValues={setBrandfetchValues}
                        brandfetchValues={brandfetchValues}
                        setAccentColor={setAccentColor}
                        logoURI={logoURI}
                        accentColor={accentColor}
                        setLogoURI={setLogoURI}
                      />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={7} height="100%">
                  <Stack className="step-four">
                    <Stack
                      bgcolor={theme.palette.background.altWhite}
                      p={{
                        lg: '18px 30px 34px 32px',
                        xl: '26px 42px 36px 38px',
                      }}
                      height="100%"
                    >
                      <DesignPreview
                        entry={currentEntry}
                        accentColor={accentColor}
                        textTone={textTone}
                        textContent={textContent}
                        imageStyle={imageStyle}
                        logo={logoURI}
                        employee={employeeName}
                        profile={employeeImageURI}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Container>
            {!isMobile && <Footer />}
          </>
        )}
      </Stack>
    </>
  );
};

export default HomePage;
