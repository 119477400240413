export const RandomIcon = ({ ...props }) => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" {...props}>
      <g
        clipPath="url(#3ywrk3212a)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#5048E5"
      >
        <path d="M.742 4.883c0-.345.24-.769.536-.947L6.454.822c.296-.178.774-.178 1.07 0l5.179 3.12c.296.179.535.602.535.949l-.002 6.219c0 .345-.24.77-.535.95L7.513 15.2c-.295.18-.773.18-1.07 0l-5.168-3.14c-.296-.18-.535-.604-.535-.95l.002-6.227zm1.089-.506 5.143 2.872 4.904-2.835L6.966 1.53 1.83 4.377zm-.332.93c0 .126-.083 5.613-.083 5.613a.6.6 0 0 0 .263.474L6.492 14.3l.045-6.048S1.499 5.179 1.499 5.306zm5.946 8.979 4.759-3.02a.79.79 0 0 0 .33-.6v-5.39l-5.09 2.93v6.08z" />
        <path d="M3.075 8.335c-.374.216-.869.057-1.106-.354-.238-.41-.127-.919.247-1.135.373-.215.869-.057 1.106.354.237.411.127.92-.247 1.135zm2.5 1.64c-.374.216-.869.058-1.106-.353-.238-.411-.127-.92.247-1.135.373-.216.869-.057 1.106.354.237.41.127.919-.247 1.135zm-2.5 1.25c-.374.216-.869.058-1.106-.353-.238-.411-.127-.92.247-1.135.373-.216.869-.057 1.106.354.237.41.127.919-.247 1.135zm2.5 1.641c-.374.216-.869.058-1.106-.353-.238-.411-.127-.92.247-1.135.373-.216.869-.058 1.106.353.237.411.127.92-.247 1.135zm6.112-6.098c-.373-.215-.869-.057-1.106.354-.237.411-.127.92.247 1.135.374.216.869.057 1.106-.354.238-.41.127-.919-.247-1.135zM10.42 9.034c-.374-.216-.87-.057-1.107.354-.237.41-.126.919.247 1.134.374.216.87.058 1.107-.353s.126-.92-.247-1.135zM9.09 11.222c-.374-.216-.87-.058-1.107.353s-.126.92.247 1.135c.374.216.87.057 1.106-.354.238-.41.127-.919-.246-1.134zM7.039 3.39c-.496 0-.898.384-.898.859s.402.86.898.86.898-.385.898-.86-.402-.86-.898-.86z" />
      </g>
      <defs>
        <clipPath id="3ywrk3212a">
          <path fill="#fff" d="M0 0h14v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
