import { Button, SxProps, styled } from '@mui/material';
import Modal from 'shared/components/Modal';
import { theme } from 'shared/styles/theme/theme';
import RandomButton from 'shared/components/RandomButton';
import MobileAccordion, { AccordionProps } from './MobileAccordion';

export interface MessageBuilderProps extends AccordionProps {
  isModalOpen: boolean;
  closeModal: () => void;
  setRandomOptions: () => void;
}

const MessageBuilderModal = ({
  isModalOpen,
  closeModal,
  setRandomOptions,
  eventType,
  eventTypes,
  setImageStyle,
  setTextTone,
  imageStyle,
  textTone,
  handleEventChange,
  setEmployeeName,
  employeeName,
  employeeImageURI,
  setEmployeeImageURI,
  isEntryReady,

  brandRouteOption,
  setBrandRouteOption,
  setBrandfetchValues,
  brandfetchValues,
  setAccentColor,
  logoURI,
  accentColor,
  setLogoURI,
  variantRange,
}: MessageBuilderProps) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      modalTitle="Create An Employee Appreciation Message"
      modalContentStyles={ModalContentCSS}
      modalTitleStyles={TitleStyles}
    >
      <Container>
        <RandomButton showText handleClick={setRandomOptions} />
        <MobileAccordion
          eventType={eventType}
          eventTypes={eventTypes}
          setImageStyle={setImageStyle}
          setTextTone={setTextTone}
          imageStyle={imageStyle}
          textTone={textTone}
          handleEventChange={handleEventChange}
          setEmployeeName={setEmployeeName}
          employeeName={employeeName}
          employeeImageURI={employeeImageURI}
          setEmployeeImageURI={setEmployeeImageURI}
          brandRouteOption={brandRouteOption}
          setBrandRouteOption={setBrandRouteOption}
          setBrandfetchValues={setBrandfetchValues}
          brandfetchValues={brandfetchValues}
          setAccentColor={setAccentColor}
          logoURI={logoURI}
          accentColor={accentColor}
          setLogoURI={setLogoURI}
          variantRange={variantRange}
          isEntryReady={isEntryReady}
        />
      </Container>
      <StyledButton onClick={closeModal} variant="pink">
        Apply Changes
      </StyledButton>
    </Modal>
  );
};

export default MessageBuilderModal;

const ModalContentCSS: SxProps = {
  width: '90vw',
  padding: '42px 22px 66px 19px',
  minHeight: 457,
  maxHeight: '90vh',
  overflow: 'scroll',
};

const TitleStyles: SxProps = {
  ...theme.typography.mobileTextHeavy,
  fontSize: 18,
  textAlign: 'center',
  maxWidth: 285,
  margin: '0 auto 24px',
};

const Container = styled('div')({
  marginBottom: 54,
});

const StyledButton = styled(Button)({
  height: 47,
});
