import { styled, InputLabel, Stack, Button } from '@mui/material';
import { uploadImage } from 'shared/utils';

interface Props {
  imageURI: string;
  setImageURI: (uri: string) => void;
  label: string;
  buttonText?: string;
  replaceButtonText?: string;
  parenthesesText?: string;
  inputId: string;
  previewAlt?: string;
}

const PhotoUpload = ({
  imageURI,
  setImageURI,
  label,
  parenthesesText,
  buttonText,
  replaceButtonText,
  inputId,
  previewAlt,
}: Props) => {
  return (
    <Stack>
      <LabelContainer direction="row">
        <InputLabel>{label}</InputLabel>
        {parenthesesText && <span> ({parenthesesText})</span>}
      </LabelContainer>
      <PhotoUploadComponent
        direction="row"
        alignItems="center"
        justifyContent={imageURI ? 'space-between' : 'flex-start'}
      >
        {imageURI && (
          <PhotoContainer>
            <PhotoPreview src={imageURI} alt={previewAlt ?? 'photo-preview'} />
          </PhotoContainer>
        )}
        <Button variant="underline" component="label" htmlFor={inputId}>
          <input
            type="file"
            id={inputId}
            onChange={(e) => uploadImage(e, setImageURI)}
            hidden
            accept="image/png, image/jpg"
          />
          {imageURI
            ? replaceButtonText ?? 'Replace Photo'
            : buttonText ?? 'Upload Photo'}
        </Button>
      </PhotoUploadComponent>
    </Stack>
  );
};

export default PhotoUpload;

const LabelContainer = styled(Stack)({
  fontSize: 12,
  whiteSpace: 'break-spaces',
});

const PhotoUploadComponent = styled(Stack)(({ theme }) => ({
  minHeight: 65,
  borderRadius: theme.shape.borderRadius?.[10],
  border: theme.border.default,
  padding: '0 12px',
}));

const PhotoContainer = styled(Stack)(({ theme }) => ({
  height: 42,
  width: 44,
  marginLeft: 0,
  borderRadius: theme.shape.borderRadius?.[10],
  border: theme.border.default,
}));

const PhotoPreview = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  maxWidth: 'calc(100% - 16px)',
  maxHeight: 'calc(100% - 14px)',
  width: 'auto',
  height: 'auto',
  margin: '7px 8px',
}));
