export const shape = {
  borderRadius: {
    5: '5px',
    10: '10px',
    50: '50px',
    topLeft10: '10px 0 0 0',
    bottomLeft10: '0 0 0 10px',
    topRight10: '0 10px 0 0',
    bottomRight10: '0 0 10px 0',
    left10: '10px 0 0 10px',
    right10: '0 10px 10px 0',
    full10: '10px',
  },
};
