import axios from 'axios';
import {
  REACT_APP_DESIGN_HUDDLE_BASE_URL,
  REACT_APP_DESIGN_HUDDLE_USER_ACCESS_TOKEN,
} from 'shared/config';

export const dshdApi = axios.create({
  baseURL: `${REACT_APP_DESIGN_HUDDLE_BASE_URL}`,
  headers: {
    Authorization: `Bearer ${REACT_APP_DESIGN_HUDDLE_USER_ACCESS_TOKEN}`,
  },
});
