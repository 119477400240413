import { MuiColorInput, MuiColorInputFormat } from 'mui-color-input';
import { styled, InputLabel, Stack } from '@mui/material';

interface Props {
  handleChange: (value: string) => void;
  color: string;
  label: string;
}

const ColorPicker = ({ color, label, handleChange }: Props) => {
  const format: MuiColorInputFormat = 'hex';

  return (
    <Stack>
      <InputLabel htmlFor={`${label}-input`}>{label}</InputLabel>
      <ColorPickerComponent
        value={color}
        onChange={handleChange}
        format={format}
        fullWidth
        id={`${label}-input`}
      />
    </Stack>
  );
};

export default ColorPicker;

const ColorPickerComponent = styled(MuiColorInput)(({ theme }) => ({
  '& .MuiButtonBase-root': {
    minHeight: 35,
    width: 35,
    borderRadius: theme.shape.borderRadius?.[50],
  },
}));
