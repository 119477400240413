import { useRef } from 'react';
import {
  styled,
  Stack,
  ButtonBase,
  ButtonBaseActions,
  Typography,
  useTheme,
} from '@mui/material';
import { BrandfetchColor } from 'shared/types';

interface Props {
  handleClick: (value: string) => void;
  brandfetchColors: BrandfetchColor[];
  color: string;
}

const BrandfetchSwatchGroup = ({
  handleClick,
  brandfetchColors,
  color,
}: Props) => {
  const actionsRef = useRef<ButtonBaseActions[]>([]);
  const theme = useTheme();

  const setActionRefs = (el: ButtonBaseActions | null, index: number) => {
    if (actionsRef.current && el) {
      return (actionsRef.current[index] = el);
    }
  };

  const handleFocus = (index: number) => {
    if (actionsRef.current) {
      actionsRef.current[index].focusVisible();
    }
  };

  return (
    <Stack>
      <Typography variant="label" mb="14px" color={theme.palette.text.darkGrey}>
        Choose an accent color:
      </Typography>
      <Stack direction="row" spacing={1}>
        {brandfetchColors.map(({ hex }, index) => {
          return (
            <Swatch
              action={(el) => setActionRefs(el, index)}
              key={`${hex}-swatch`}
              backgroundColor={hex}
              onClick={() => handleClick(hex)}
              active={hex === color}
              onFocus={() => handleFocus(index)}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default BrandfetchSwatchGroup;

const Swatch = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'active',
})<{ backgroundColor?: string; active: boolean }>(
  ({ theme, backgroundColor, active }) => ({
    backgroundColor,
    borderRadius: theme.shape.borderRadius?.[50],
    minHeight: 36,
    width: 36,
    outline: active ? `3px solid ${theme.palette.common.black}` : 'none',
    border: `2px solid ${theme.palette.background.altWhite}`,

    '&:focus': {
      outline: `3px solid ${theme.palette.common.black}`,
    },
  }),
);
