export const ArrowLeftIcon = ({ ...props }) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.0411 5.75537C0.748205 6.04827 0.748205 6.52314 1.0411 6.81603L5.81407 11.589C6.10696 11.8819 6.58184 11.8819 6.87473 11.589C7.16762 11.2961 7.16762 10.8212 6.87473 10.5283L2.63209 6.2857L6.87473 2.04306C7.16762 1.75017 7.16762 1.2753 6.87473 0.982404C6.58184 0.689511 6.10696 0.689511 5.81407 0.982404L1.0411 5.75537ZM13 5.53571L1.57143 5.5357L1.57143 7.0357L13 7.03571L13 5.53571Z"
        fill="black"
      />
    </svg>
  );
};
