import { useSnackbar, SnackbarMessage } from 'notistack';
import { Alert } from '@mui/material';
import { forwardRef } from 'react';
import { CheckCircleOutlineIcon } from 'static/icons';

interface Props {
  id: string;
  message: SnackbarMessage;
  severity: 'success' | 'error' | 'warning';
}

const ToastNotification = forwardRef<HTMLDivElement, Props>(
  function ToastNotification({ id, message, severity }, ref) {
    const { closeSnackbar } = useSnackbar();

    return (
      <Alert
        ref={ref}
        role="alert"
        variant="filled"
        severity={severity}
        sx={{ whiteSpace: 'pre-line' }}
        iconMapping={{
          success: <CheckCircleOutlineIcon />,
        }}
        onClose={() => closeSnackbar(id)}
      >
        {message}
      </Alert>
    );
  },
);

export default ToastNotification;
