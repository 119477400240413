export const DownloadIcon = ({ ...props }) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
      <path
        d="M10.5 13.405a.894.894 0 0 1-.305-.051.709.709 0 0 1-.264-.173l-2.925-2.925a.77.77 0 0 1-.223-.569c0-.23.074-.42.223-.568a.82.82 0 0 1 .579-.234.753.753 0 0 1 .58.213l1.523 1.524v-5.81c0-.23.077-.423.233-.579A.786.786 0 0 1 10.5 4c.23 0 .423.078.58.233.155.156.232.35.232.58v5.809l1.524-1.524a.753.753 0 0 1 .58-.213c.236.007.429.085.578.234a.77.77 0 0 1 .223.569.77.77 0 0 1-.223.568l-2.925 2.925a.708.708 0 0 1-.264.173.894.894 0 0 1-.305.05zM5.625 17c-.447 0-.83-.159-1.147-.477A1.565 1.565 0 0 1 4 15.375V13.75c0-.23.078-.423.233-.58a.788.788 0 0 1 .58-.232c.23 0 .423.077.579.233.155.156.233.349.233.579v1.625h9.75V13.75c0-.23.078-.423.234-.58a.785.785 0 0 1 .579-.232c.23 0 .422.077.578.233.156.156.234.349.234.579v1.625c0 .447-.159.83-.477 1.148a1.567 1.567 0 0 1-1.148.477h-9.75z"
        fill="#fff"
      />
    </svg>
  );
};
