export const ArrowRightIcon = ({ ...props }) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8161 6.1018C13.109 5.80891 13.109 5.33404 12.8161 5.04114L8.04311 0.268173C7.75022 -0.0247209 7.27534 -0.024721 6.98245 0.268172C6.68956 0.561066 6.68956 1.03594 6.98245 1.32883L11.2251 5.57147L6.98245 9.81411C6.68955 10.107 6.68955 10.5819 6.98245 10.8748C7.27534 11.1677 7.75021 11.1677 8.04311 10.8748L12.8161 6.1018ZM0.857178 6.32147L12.2857 6.32147L12.2857 4.82147L0.857178 4.82147L0.857178 6.32147Z"
        fill="white"
      />
    </svg>
  );
};
