import { styled, Button, Typography, SxProps, Box } from '@mui/material';
import Modal from 'shared/components/Modal';
import { useTour } from '@reactour/tour';

interface Props {
  open: boolean;
  onClose: (bool: boolean) => void;
}

export default function TutorialModal({ open, onClose }: Props) {
  const { setIsOpen } = useTour();

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      modalTitle="Welcome!"
      modalContentStyles={ModalContentCSS}
      modalTitleStyles={ModalTitleStyles}
    >
      <ModalText>
        Ready to discover how to craft personalized, impactful appreciation
        communications for your team members in less than 30 seconds?
      </ModalText>
      <ModalText>
        Boost morale and foster a positive work environment, all with just a few
        clicks.
      </ModalText>
      <Box maxWidth={185} margin="auto">
        <Button
          variant="black"
          onClick={() => {
            setIsOpen(true);
            onClose(false);
          }}
        >
          Let&lsquo;s Get Started!
        </Button>
      </Box>
    </Modal>
  );
}

const ModalContentCSS: SxProps = {
  maxWidth: 450,
  padding: '15px 32px 38px',

  '& .close-button': {
    right: -15,
    '& svg': {
      width: 15,
      height: 15,
    },
  },
};

const ModalTitleStyles: SxProps = {
  margin: '18px auto 10px',
  textAlign: 'center',
  fontSize: 20,
  fontWeight: 700,
};

const ModalText = styled(Typography)(({ theme }) => ({
  ...theme.typography.labelMedium,
  color: theme.palette.text.grey,
  marginBottom: 19,
  textAlign: 'center',
  maxWidth: 380,
}));
