import { useState } from 'react';
import Button from 'shared/components/Button';
import { REACT_APP_DESIGN_HUDDLE_BASE_URL } from 'shared/config';
import { useEnqueueSnackbar } from 'shared/hooks/useEnqueueSnackbar';
import { pollExportJob } from 'shared/utils';

interface Props {
  token: string;
  templateCode: string;
  projectPageId: string;
  projectId: string;
}

const ExportButton = ({
  token,
  templateCode,
  projectPageId,
  projectId,
}: Props) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const highResExport = (projectId: string) => {
    const url =
      REACT_APP_DESIGN_HUDDLE_BASE_URL + '/projects/' + projectId + '/export';
    setIsLoading(true);
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      headers: {
        authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        format: 'jpg',
        filename: templateCode,
        page_id: projectPageId,
      }),
    }).then((response) => {
      response.json().then((json) => {
        if (response.ok && json.success && json.data) {
          pollExportJob({
            internal_project_id: projectId,
            job_id: json.data.job_id,
            token,
            attempt: 1,
            setIsLoading,
            snackBar: enqueueSnackbar,
          });
        } else {
          setIsLoading(false);
          enqueueSnackbar('Failed to download image', {
            key: 'image-export-error',
            variant: 'error',
          });
          throw new Error('Export Error');
        }
      });
    });
  };

  return (
    <Button
      text="Save Image"
      variant="pink"
      onClick={() => {
        highResExport(projectId);
      }}
      loading={isLoading}
    />
  );
};

export default ExportButton;
