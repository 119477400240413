import { theme } from './theme/theme';

export const popoverStyles = {
  paddingTop: 26,
  borderRadius: theme.shape.borderRadius['10'],
};

export const dotStyles = (
  base: {
    [key: string]: any;
  },
  state?: {
    [key: string]: any;
  },
) => {
  return {
    ...base,
    height: 14,
    width: 14,
    border: 'none',
    transform: 'scale(1)',
    background: state?.current
      ? theme.palette.tutorialNavigation.dotActive
      : theme.palette.tutorialNavigation.dot,
  };
};

export const controlStyles = {
  marginTop: 0,
};
