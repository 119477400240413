import { useState, ChangeEvent, useEffect } from 'react';
import {
  InputLabel,
  Stack,
  IconButton,
  Autocomplete,
  TextField,
  useTheme,
} from '@mui/material';
import { SearchIcon } from 'static/icons/SearchIcon';
import Button from 'shared/components/Button';
import {
  BrandfetchColor,
  BrandfetchSearchResult,
  BrandfetchSelectionResult,
  BrandfetchLogo,
} from 'shared/types';
import { REACT_APP_BRANDFETCH_API_KEY } from 'shared/config';
import { useEnqueueSnackbar } from 'shared/hooks/useEnqueueSnackbar';

interface Props {
  setBrandfetchValues: (
    brandfetchValues: BrandfetchSelectionResult | undefined,
  ) => void;
  companyParams: string | null;
}

const BrandSearch = ({ setBrandfetchValues, companyParams }: Props) => {
  const [searchResults, setSearchResults] = useState<BrandfetchSearchResult[]>(
    [],
  );
  const [inputValue, setInputValue] = useState<string | null>(null);

  const theme = useTheme();
  const enqueueSnackbar = useEnqueueSnackbar();

  useEffect(() => {
    if (companyParams) {
      setInputValue(companyParams);
      handleBrandfetchSelection(companyParams);
    }
  }, []);

const searchBrandfetch = async (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const query = event.target.value;

  // Check if the query starts with http:// or https://
  if (/^https?:\/\//.test(query)) {
    enqueueSnackbar("Please enter a URL without 'http://' or 'https://'", {
      key: 'brandfetch-invalid-url',
      variant: 'error',
    });
    return; // Don't perform the search
  }

  if (query) {
    const results = await fetch(
      `https://api.brandfetch.io/v2/search/${query}`,
    );

    const searchResultsJson = await results.json();
    setSearchResults(searchResultsJson);
  }
};

  const handleBrandfetchSelection = (domain: string) => {
    fetch(`https://api.brandfetch.io/v2/brands/${domain}`, {
      headers: {
        Authorization: `Bearer ${REACT_APP_BRANDFETCH_API_KEY}`,
      },
    })
      .then((response) => response.json())
      .then((brand: BrandfetchSelectionResult) => {
        const { colors, logos } = brand;

        brand.colors = handleBrandColors(colors);
        brand.logos = handleBrandLogos(logos);
        setBrandfetchValues(brand);
      })
      .catch((error) => {
        console.error('error in Brandfetch search: ', error);
        enqueueSnackbar(
          'An error occurred while fetching that brand. Please try again later.',
          {
            key: 'brandfetch-search-error',
            variant: 'error',
          },
        );
      });
  };

  const handleBrandColors = (colors: BrandfetchColor[]) => {
    let blackHexIndex: number | null = null;

    const filteredColors = colors.filter((color, index) => {
      const isBlack = color.hex === theme.palette.common.black;
      blackHexIndex = isBlack ? index : blackHexIndex;

      return color.hex !== theme.palette.common.white && color.brightness < 230;
    });

    if (blackHexIndex !== null) {
      filteredColors.push(filteredColors.splice(blackHexIndex, 1)[0]);
    }

    return filteredColors;
  };

  const handleBrandLogos = (logos: BrandfetchLogo[]) => {
    const priority = ['icon', 'logo', 'symbol', 'other'];

    return logos
      .sort((a, b) => {
        const aIndex = priority.indexOf(a.type);
        const bIndex = priority.indexOf(b.type);

        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        if (aIndex === bIndex) return 0;
        return aIndex > bIndex ? 1 : -1;
      })
      .slice(0, 6);
  };

  return (
    <Stack component="form" role="search">
      <InputLabel htmlFor={`search-input`}>Your Company Website:</InputLabel>
      <Autocomplete
        options={searchResults.map((result) => result.domain)}
        id="search-input"
        freeSolo
        autoSelect
        sx={{ paddingRight: 0 }}
        value={inputValue}
        onChange={(_event, domain: string | null) => {
          setInputValue(domain);
          if (domain) {
            handleBrandfetchSelection(domain);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        renderInput={(params) => {
          return (
            <TextField
              variant="outlined"
              {...params}
              placeholder="Enter your company's site URL"
              spellCheck="false"
              type="search"
              name="search"
              onChange={(event) => searchBrandfetch(event)}
              InputProps={{
                ...params.InputProps,
                endAdornment: inputValue ? (
                  <Button
                    onClick={() => {
                      setBrandfetchValues(undefined);
                      setInputValue('');
                    }}
                    type="button"
                    variant="text"
                    text="Reset"
                  />
                ) : (
                  <IconButton
                    type="submit"
                    size="large"
                    onClick={(event) => event.preventDefault()}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          );
        }}
      />
    </Stack>
  );
};

export default BrandSearch;
