export const CopyIcon = ({ ...props }) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
      <g clipPath="url(#qkqxc1n1ga)">
        <path
          d="M8.628 14.2c-.333 0-.617-.124-.854-.374a1.263 1.263 0 0 1-.356-.9v-7.65c0-.352.119-.652.356-.902S8.295 4 8.628 4h5.441c.333 0 .617.125.854.374.237.25.355.55.355.901v7.65c0 .351-.118.651-.355.901s-.521.374-.854.374H8.628zm0-1.274h5.441v-7.65H8.628v7.65zM6.209 16.75c-.332 0-.617-.125-.854-.375a1.264 1.264 0 0 1-.355-.9V7.188c0-.181.058-.332.174-.455a.57.57 0 0 1 .43-.183c.172 0 .316.061.432.183a.636.636 0 0 1 .173.455v8.288h6.046a.57.57 0 0 1 .431.183.634.634 0 0 1 .174.454c0 .18-.058.332-.174.454a.57.57 0 0 1-.43.184H6.208zM8.628 5.275v7.65-7.65z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="qkqxc1n1ga">
          <path fill="#fff" d="M0 0h21v21H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
