import { styled, Stack, Typography } from '@mui/material';
import { Container } from 'shared/components/styledComponents';

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterComponent>
      <Container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="footer">© Copyright {year}</Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <FooterLink href="https://www.changeengine.com/" target="_blank">
            Learn More
          </FooterLink>
          <FooterLink href="https://www.changeengine.com/contact" target="_blank">
            Contact Us
          </FooterLink>
          <FooterLink href="https://www.changeengine.com/demo" target="_blank">
            Request Demo
          </FooterLink>
        </Stack>
      </Container>
    </FooterComponent>
  );
};

export default Footer;

const FooterComponent = styled('footer')(({ theme }) => ({
  fontSize: theme.typography.footer.fontSize,
  color: theme.palette.text.white,
  height: 31,
  backgroundColor: theme.palette.background.blueGrey,
}));

const FooterLink = styled('a')(({ theme }) => ({
  fontWeight: 700,
  textDecoration: 'none',
  cursor: 'pointer',
  color: theme.palette.text.white,

  '&:hover': {
    textDecoration: 'underline',
  },
}));
