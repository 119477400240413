import { z } from 'zod';

const envSchema = z.object({
  REACT_APP_AIRTABLE_API_KEY: z.string(),
  REACT_APP_AIRTABLE_BASE_ID: z.string(),
  REACT_APP_AIRTABLE_PRIMARY_TABLE_NAME: z.string(),
  REACT_APP_BRANDFETCH_API_KEY: z.string(),
  REACT_APP_DESIGN_HUDDLE_CLIENT_ID: z.string(),
  REACT_APP_DESIGN_HUDDLE_USER_ACCESS_TOKEN: z.string(),
  REACT_APP_DESIGN_HUDDLE_BASE_URL: z.string(),
  REACT_APP_CLOUDINARY_BASE_URL: z.string(),
  REACT_APP_CLOUDINARY_CLOUD_NAME: z.string(),
  REACT_APP_CLOUDINARY_API_KEY: z.string(),
  REACT_APP_CLOUDINARY_API_SECRET: z.string(),
});

const env = envSchema.parse(process.env);

export const REACT_APP_AIRTABLE_API_KEY = env.REACT_APP_AIRTABLE_API_KEY;
export const REACT_APP_AIRTABLE_BASE_ID = env.REACT_APP_AIRTABLE_BASE_ID;
export const REACT_APP_AIRTABLE_PRIMARY_TABLE_NAME =
  env.REACT_APP_AIRTABLE_PRIMARY_TABLE_NAME;
export const REACT_APP_BRANDFETCH_API_KEY = env.REACT_APP_BRANDFETCH_API_KEY;
export const REACT_APP_DESIGN_HUDDLE_CLIENT_ID =
  env.REACT_APP_DESIGN_HUDDLE_CLIENT_ID;
export const REACT_APP_DESIGN_HUDDLE_USER_ACCESS_TOKEN =
  env.REACT_APP_DESIGN_HUDDLE_USER_ACCESS_TOKEN;
export const REACT_APP_DESIGN_HUDDLE_BASE_URL =
  env.REACT_APP_DESIGN_HUDDLE_BASE_URL;
export const REACT_APP_CLOUDINARY_BASE_URL = env.REACT_APP_CLOUDINARY_BASE_URL;
export const REACT_APP_CLOUDINARY_CLOUD_NAME =
  env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const REACT_APP_CLOUDINARY_API_KEY = env.REACT_APP_CLOUDINARY_API_KEY;
export const REACT_APP_CLOUDINARY_API_SECRET =
  env.REACT_APP_CLOUDINARY_API_SECRET;
