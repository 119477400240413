import { styled, Stack, Typography } from '@mui/material';
import { Container } from 'shared/components/styledComponents';
import Button from './Button';
import EALogo from 'static/logos/EALogo';
import CELogo from 'static/logos/CELogo';

const Header = () => {
  return (
    <HeaderComponent role="banner">
      <Container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <a
            href="https://www.changeengine.com/"
            target="_blank"
            rel="noreferrer"
          >
            <CELogo />
          </a>
          <a
            href="https://www.changeengine.com/"
            target="_blank"
            rel="noreferrer"
          >
            <EALogo />
          </a>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Copy paragraph={true} variant="header">
            Create an employee appreciation communication in less than 30
            seconds
          </Copy>
          <Button
            href="https://www.changeengine.com/demo"
            type="button"
            variant="pink"
            text="Automate Scheduling"
          />
        </Stack>
      </Container>
    </HeaderComponent>
  );
};

export default Header;

const HeaderComponent = styled('header')(({ theme }) => ({
  fontSize: theme.typography.footer.fontSize,
  color: theme.palette.text.white,
  height: 99,
  backgroundColor: theme.palette.background.white,
}));

const Copy = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    color: theme.palette.text.grey,
    textAlign: 'right',
    maxWidth: 396,
    margin: '0 36px 0 auto',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 437,
  },
}));
