import { styled, Typography } from '@mui/material';
import Button from './Button';

interface Props {
  openModal: () => void;
}

const MobileEditButton = ({ openModal }: Props) => {
  return (
    <HeaderComponent role="banner">
      <Copy>Employee Message</Copy>
      <Button variant="pink" text="Edit Selections" onClick={openModal} />
    </HeaderComponent>
  );
};

export default MobileEditButton;

const HeaderComponent = styled('header')(({ theme }) => ({
  fontSize: theme.typography.footer.fontSize,
  height: 65,
  backgroundColor: theme.palette.background.white,
  width: '100%',
  marginBottom: 20,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: 351,
  padding: '0 23px 0 16px',
}));

const Copy = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.grey,
  textAlign: 'right',
  maxWidth: 203,
  ...theme.typography.mobileTextHeavy,
}));
