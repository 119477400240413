import { styled, Typography, Stack } from '@mui/material';
import CELogo from 'static/logos/CELogo';
import EALogo from 'static/logos/EALogo';

const MobileHeader = () => {
  return (
    <HeaderComponent role="banner">
      <Stack>
        <a
          href="https://www.changeengine.com/"
          target="_blank"
          rel="noreferrer"
        >
          <CELogo height={16} width={80} />
        </a>
        <a
          href="https://www.changeengine.com/"
          target="_blank"
          rel="noreferrer"
        >
          <EALogo height={16} />
        </a>
      </Stack>
      <Copy>
        Create an employee appreciation communication in less than 30 seconds.{' '}
        <a
          href="https://www.changeengine.com/demo"
          target="_blank"
          rel="noreferrer"
        >
          Automate Scheduling
        </a>
      </Copy>
    </HeaderComponent>
  );
};

export default MobileHeader;

const HeaderComponent = styled('header')(({ theme }) => ({
  fontSize: theme.typography.footer.fontSize,
  color: theme.palette.text.white,
  height: 65,
  backgroundColor: theme.palette.background.white,
  width: '100%',
  marginBottom: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 20px 0 25px',
}));

const Copy = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.grey,
  textAlign: 'right',
  width: 203,
  lineHeight: 1.25,
  ...theme.typography.mobileText,

  '& a': {
    fontWeight: 700,
  },
}));
