import Airtable, { Record, FieldSet } from 'airtable';
import { mapAirtableTypes } from 'shared/utils';
import {
  REACT_APP_AIRTABLE_API_KEY,
  REACT_APP_AIRTABLE_BASE_ID,
  REACT_APP_AIRTABLE_PRIMARY_TABLE_NAME,
} from 'shared/config';

const airtableApiKey = REACT_APP_AIRTABLE_API_KEY;
const airtableBaseId = REACT_APP_AIRTABLE_BASE_ID;
const airtablePrimaryTableName = REACT_APP_AIRTABLE_PRIMARY_TABLE_NAME;

const base = new Airtable({ apiKey: airtableApiKey }).base(airtableBaseId);

export const fetchAirtableData = async () => {
  const completedPromise = new Promise<Record<FieldSet>[]>(
    (resolve, reject) => {
      const airTableRecords: Record<FieldSet>[] = [];

      base(airtablePrimaryTableName)
        .select({
          view: 'EmployeeAppreciator',
        })
        .eachPage(
          (records, fetchNextPage) => {
            airTableRecords.push(...records);
            fetchNextPage();
          },
          (err) => {
            if (err) {
              // TODO: add Sentry error logging
              console.error(err);
              reject(err);
              return;
            }

            return resolve(airTableRecords);
          },
        );
    },
  );

  const results = await completedPromise;
  const allData = results.map((result) => result.fields);
  const activeResults = allData.filter(
    (result) => result['CMS Item Status'] === 'Active',
  );
  const typedData = mapAirtableTypes(activeResults);
  const eventTypes = typedData.map(
    ({ 'Moment Title': eventType }) => eventType,
  );
  return {
    typedData,
    eventTypes,
  };
};
