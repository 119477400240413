export const typography = {
  fontFamily: `'Inter', sans-serif`,
  body1: {
    fontSize: '14px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '16px',
    fontWeight: 600,
  },
  body3: {
    fontSize: '18px',
    fontWeight: 600,
  },
  footer: {
    fontSize: '10px',
    fontWeight: 500,
  },
  header: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: 'normal',
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: '6px',
  },
  labelMedium: {
    fontSize: '15px',
    fontWeight: 500,
    marginBottom: '14px',
  },
  tutorialTitle: {
    fontSize: 16,
    lineHeight: 1.3,
    fontFamily: 'Avenir-Heavy, sans-serif',
  },
  tutorialText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.2,
    fontFamily: 'Avenir, sans-serif',
  },
  mobileText: {
    fontSize: 12,
    fontFamily: 'Avenir-Medium, sans-serif',
  },
  mobileTextHeavy: {
    fontSize: 14,
    fontFamily: 'Avenir-Heavy, sans-serif',
  },
  mobileBoldHeavy: {
    fontSize: 15,
    fontFamily: 'AvenirNext-DemiBold, sans-serif',
  },
};
