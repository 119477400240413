import { Skeleton, styled, Stack } from '@mui/material';
import Markdown from 'markdown-to-jsx';

interface Props {
  textContent?: string;
}

const Text = ({ textContent }: Props) => {
  return (
    <Stack>
      {textContent ? (
        <TextContent>{textContent}</TextContent>
      ) : (
        <>
          <Skeleton variant="text" sx={{ mb: '24px' }} width={90} />
          <Skeleton variant="text" sx={{ mb: '24px' }} width="50%" />
          <Skeleton variant="rectangular" sx={{ mb: '24px' }} height={150} />
          <Skeleton variant="text" width={90} />
          <Skeleton variant="text" width={100} />
        </>
      )}
    </Stack>
  );
};

export default Text;

const TextContent = styled(Markdown)(({ theme }) => ({
  whiteSpace: 'pre-line',
  '& p': {
    margin: '0 0 24px',
  },
  [theme.breakpoints.down('lg')]: {
    ...theme.typography.mobileText,
  },
}));
