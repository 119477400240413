import { useEffect, useState, useRef } from 'react';
import {
  styled,
  Stack,
  Typography,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  DesignHuddleProject,
  AirtableEntry,
  DesignHuddleConfig,
  Page,
} from 'shared/types';
import {
  IMAGE_PREVIEW_WIDTH,
  IMAGE_STYLE_PAGE_NUMBERS,
  PAGE_NUMBERS_IMAGE_STYLE,
} from 'shared/constants';
import Modal from 'shared/components/Modal';
import {
  createDesignHuddleConfig,
  getRelevantTemplates,
  getRelevantProject,
} from 'shared/utils';
import { useEnqueueSnackbar } from 'shared/hooks/useEnqueueSnackbar';
import ButtonGroup from './components/ButtonGroup';
import PreviewImage from './components/PreviewImage';
import Text from './components/Text';
import { REACT_APP_DESIGN_HUDDLE_CLIENT_ID } from 'shared/config';
import debounce from 'lodash.debounce';
import ExportButton from './components/ExportButton';

export interface DesignPreviewProps {
  entry?: AirtableEntry;
  accentColor: string;
  textTone: string;
  textContent: string;
  imageStyle: string;
  logo: string;
  employee?: string;
  profile?: string;
}

export function DesignPreview({
  entry,
  accentColor,
  textTone,
  textContent,
  imageStyle,
  logo,
  profile,
  employee,
}: DesignPreviewProps) {
  const [pages, setPages] = useState<Page[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [imagePreview, setImagePreview] = useState('');
  const [templateCode, setTemplateCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wordCount, setWordCount] = useState<number>();
  const [readSeconds, setReadSeconds] = useState<number>(0);
  const [internalProjectId, setInternalProjectId] = useState<string>('');
  const [pageId, setPageId] = useState<string>('');
  const [projectPageId, setProjectPageId] = useState<string>('');
  const [projectConfig, setProjectConfig] = useState<DesignHuddleConfig | any>(
    {},
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [guestUserAccessToken, setGuestUserAccessToken] = useState<string>('');

  const designHuddle = useRef<HTMLDivElement>(null);
  const enqueueSnackbar = useEnqueueSnackbar();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  // Configure DS
  useEffect(() => {
    //@ts-ignore
    DSHDLib.configure({
      domain: 'changeengine.designhuddle.com',
      client_id: REACT_APP_DESIGN_HUDDLE_CLIENT_ID,
      visitor: true,
    });
  }, []);

  useEffect(() => {
    if (entry) {
      /**
       * IMPORTANT: 'DesignHuddle Template ID' is mislabeled in Airtable,
       * but is actually the template_code in Design Huddle
       */
      setTemplateCode(entry['DesignHuddle Template ID']);
      // Reset so export button sends correct request
      setGuestUserAccessToken('');
      setProjectPageId('');
      const getTemplates = async () => {
        const response = await getRelevantTemplates(
          entry['DesignHuddle Template ID'],
        );
        setInternalProjectId(response.data.items[0].internal_project_id);
        const secondResponse = await getRelevantProject(
          response.data.items[0].internal_project_id,
        );

        setPages(secondResponse.data.pages);

        const correctPage = secondResponse.data.pages.filter(
          (page: Page) =>
            page.page_number === IMAGE_STYLE_PAGE_NUMBERS[imageStyle],
        );
        setPageId(correctPage[0].page_id);
        setProjectPageId(correctPage[0].page_id);
      };
      getTemplates();
    }
  }, [entry]);

  const getAllPagesPreviewImages = (pages: Page[]) => {
    const imageUrls = pages.reduce(
      (result: string[], { page_id, page_number }: Page) => {
        const config = createDesignHuddleConfig({
          templateCode,
          accentColor,
          logo,
          imageStyle: PAGE_NUMBERS_IMAGE_STYLE[page_number],
          width: IMAGE_PREVIEW_WIDTH,
          employee,
          profile,
          pageId: page_id,
        });

        //@ts-ignore
        DSHDLib.getVariableTemplatePreviewURL(
          config,
          (error: any, url: string) => {
            if (error) {
              console.error('Error in retrieving preview urls', error);
              // TODO: find out if CE wants to use Sentry
              return;
            }

            if (url) result.push(url);
          },
        );

        return result;
      },
      [],
    );

    setImageUrls(imageUrls);
  };

  useEffect(() => {
    imageUrls?.forEach((previewImageUrl) => {
      const i = new Image();
      i.src = previewImageUrl;
    });
  }, [imageUrls]);

  // Update image preview on logo, imageStyle, or accentColor change
  useEffect(() => {
    if (templateCode) {
      const debouncedPreview = debounce(handleDesignHuddlePreview, 300);
      const debouncedAllPagesPreview = debounce(getAllPagesPreviewImages, 300);
      debouncedPreview();
      debouncedAllPagesPreview(pages);

      return () => {
        debouncedPreview.cancel();
        debouncedAllPagesPreview.cancel();
      };
    }
  }, [templateCode, imageStyle, logo, accentColor, profile, employee, pageId]);

  useEffect(() => {
    if (pages.length > 0) {
      const page = pages.filter(
        (page: Page) =>
          page.page_number === IMAGE_STYLE_PAGE_NUMBERS[imageStyle],
      )[0];
      setProjectPageId(page.page_id);
      setPageId(page.page_id);
    }
  }, [imageStyle]);

  // Update text content when textTone changes
  useEffect(() => {
    if (entry) {
      if (entry[`${textTone} Read Time`]) {
        const readTime = entry[`${textTone} Read Time`] as number;
        const seconds = Math.round(readTime);

        setReadSeconds(seconds);
      }

      if (entry[`${textTone} Word Count`]) {
        const wordCount = entry[`${textTone} Word Count`] as number;
        setWordCount(wordCount);
      }
    }
  }, [textTone, entry]);

  // Create downloadable image url when image changes
  useEffect(() => {
    let tempImage = new Image();
    setIsLoading(true);
    if (imagePreview) {
      tempImage.src = imagePreview;
      tempImage.addEventListener('load', function () {
        setIsLoading(false);
      });
    }
  }, [imagePreview]);

  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(textContent);
      enqueueSnackbar('Text has been copied!', {
        key: 'clipboard-success',
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(
        'An error occurred while trying to copy text. Please try again.',
        {
          key: 'clipboard-error',
          variant: 'error',
        },
      );

      // TODO: find out if CE wants to use Sentry
      console.error('Failed to copy: ', err);
    }
  };

  const handleDesignHuddlePreview = () => {
    if (accentColor === '#' || !accentColor) return;

    const config = createDesignHuddleConfig({
      templateCode,
      accentColor,
      logo,
      imageStyle,
      width: IMAGE_PREVIEW_WIDTH,
      employee,
      profile,
      pageId,
    });
    setProjectConfig(config);
    //@ts-ignore
    DSHDLib.getVariableTemplatePreviewURL(config, (error: any, url: string) => {
      if (error) {
        enqueueSnackbar(
          'Oops! There was an error getting the image preview. Please try again later.',
          {
            key: 'image-preview-error',
            variant: 'error',
          },
        );

        // TODO: find out if CE wants to use Sentry
        return;
      }
      setImagePreview(url);
    });
  };

  const handleOpenDesign = () => {
    const config = createDesignHuddleConfig({
      templateCode,
      accentColor,
      logo,
      profile,
      employee,
    });
    //@ts-ignore
    DSHDLib.createProject(
      config,
      async (error: any, data: { project_id: string }) => {
        if (error) {
          enqueueSnackbar(
            'An error occurred creating the Design Huddle project. Please try again later.',
            {
              key: 'create-project-error',
              variant: 'error',
            },
          );

          return;
        }

        const { project_id } = data;
        setInternalProjectId(project_id);
        // @ts-ignore
        DSHDLib.getGuestAccessToken(
          {},
          async (error: any, accessToken: string) => {
            setGuestUserAccessToken(accessToken);
          },
        );

        //@ts-ignore
        await DSHDLib.Editors.insert(
          designHuddle.current,
          {
            project_id,
            page_number: IMAGE_STYLE_PAGE_NUMBERS[imageStyle],
            hide_right_panel: true,
          },
          (error: any, editor: any) => {
            if (!error) {
              editor.getProjectData(
                {},
                (error: unknown, data: DesignHuddleProject) => {
                  if (error) {
                    enqueueSnackbar(
                      'Oops! There was an error getting this Design Huddle project.',
                      {
                        key: 'get-project-error',
                        variant: 'error',
                      },
                    );
                    return;
                  }
                  setProjectPageId(data.current_page_id);
                  setPageId(data.current_page_id);
                },
              );
              editor.handleProjectDataChange(() => {
                handleUpdateDesign(editor);
              });
              editor.handlePageChange(() => {
                handleUpdateDesign(editor);
              });
            }
          },
        );
      },
    );
  };

  const handleUpdateDesign = (editor: any) => {
    editor.getProjectData({}, (error: any, data: DesignHuddleProject) => {
      if (error) {
        enqueueSnackbar(
          'Oops! There was an error getting this Design Huddle project.',
          {
            key: 'get-project-error',
            variant: 'error',
          },
        );
        console.error({ error });
        return;
      }
      setProjectPageId(data.current_page_id);

      editor.getRender(
        {
          page_id: data.current_page_id,
        },
        (
          error: any,
          data: {
            width: number;
            height: number;
            page_id: string;
            url: string;
          },
        ) => {
          if (error) {
            console.error({ error });
            return;
          }

          const renderDataUrl = data.url;
          setImagePreview(renderDataUrl);
        },
      );
    });
  };

  const handleOpenModal = () => {
    //@ts-ignore
    DSHDLib.configure({
      domain: 'changeengine.designhuddle.com',
      client_id: REACT_APP_DESIGN_HUDDLE_CLIENT_ID,
      guest: true,
    });

    setIsModalOpen(true);
    handleOpenDesign();
  };

  return (
    <>
      <Stack>
        <PreviewImage imagePreview={imagePreview} isLoading={isLoading} />
        <Text textContent={textContent} />
        {wordCount && (
          <WordCount>
            Word count: {wordCount} / Average read time:{' '}
            {readSeconds && `${readSeconds} seconds`}
          </WordCount>
        )}
        {!isMobile && (
          <ButtonGroup
            handleShowModal={() => handleOpenModal()}
            handleTextCopy={() => copyText()}
            templateCode={entry ? entry['DesignHuddle Template ID'] : ''}
            internal_project_id={internalProjectId}
            config={projectConfig}
            projectPageId={projectPageId}
            accessToken={guestUserAccessToken}
          />
        )}
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          modalTitle="Edit Image"
          modalContentStyles={ModalContentCSS}
          modalTitleStyles={ModalTitleCSS}
        >
          <div
            ref={designHuddle}
            style={{ width: '100%', height: '100%' }}
          ></div>
          <Stack margin="20px 0 0 auto" width={isMobile ? '100%' : 183}>
            <ExportButton
              token={guestUserAccessToken}
              templateCode={templateCode}
              projectPageId={projectPageId}
              projectId={internalProjectId}
            />
          </Stack>
        </Modal>
      </Stack>
      {isMobile && (
        <ButtonGroup
          handleShowModal={() => handleOpenModal()}
          handleTextCopy={() => copyText()}
          templateCode={entry ? entry['DesignHuddle Template ID'] : ''}
          internal_project_id={internalProjectId}
          config={projectConfig}
          projectPageId={projectPageId}
          accessToken={guestUserAccessToken}
        />
      )}
    </>
  );
}

const ModalContentCSS: SxProps = {
  width: '90vw',
  height: '85vh',
  padding: '22px 24px 15px',
};

const ModalTitleCSS: SxProps = {
  marginBottom: '24px',
};

const WordCount = styled(Typography)(({ theme }) => ({
  fontStyle: 'oblique;',
  fontWeight: 500,
  color: theme.palette.text.pink,
  marginBottom: 21,
}));
