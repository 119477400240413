import { styled, Box } from '@mui/material';

export const Container = styled(Box)<{ margin?: string }>(
  ({ theme, margin }) => ({
    display: 'flex',
    maxWidth: '76%',
    width: '100%',
    margin: margin ?? '0 auto',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      maxWidth: 880,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1040,
    },
  }),
);
