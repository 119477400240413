import { Stack, Typography, useTheme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';
import BrandSearch from 'shared/components/BrandSearch';
import BrandfetchLogoGroup from 'shared/components/BrandfetchLogoGroup';
import BrandfetchSwatchGroup from 'shared/components/BrandfetchSwatchGroup';
import ColorPicker from 'shared/components/ColorPicker';
import PhotoUpload from 'shared/components/PhotoUpload';
import ToggleGroup from 'shared/components/ToggleGroup';
import { BrandRouteOptions, QueryParams } from 'shared/constants';
import { BrandfetchSelectionResult } from 'shared/types';

export interface BrandFetchProps {
  brandRouteOption: string;
  setBrandRouteOption: Dispatch<SetStateAction<string>>;
  setBrandfetchValues: Dispatch<
    SetStateAction<BrandfetchSelectionResult | undefined>
  >;
  brandfetchValues: BrandfetchSelectionResult | undefined;
  accentColor: string;
  setAccentColor: Dispatch<SetStateAction<string>>;
  logoURI: string;
  setLogoURI: Dispatch<SetStateAction<string>>;
}

const BrandFetchGroup = ({
  brandRouteOption,
  setBrandRouteOption,
  setBrandfetchValues,
  brandfetchValues,
  setAccentColor,
  logoURI,
  accentColor,
  setLogoURI,
}: BrandFetchProps) => {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const companyParams = searchParams.get(QueryParams.Company);

  return (
    <>
      <Typography variant="labelMedium" color={theme.palette.text.grey}>
        Select your preferred route:
      </Typography>
      <Stack
        spacing={
          brandRouteOption === BrandRouteOptions.Brandfetch ? '20px' : '14px'
        }
      >
        <ToggleGroup
          value={brandRouteOption}
          handleChange={(value: string) => setBrandRouteOption(value)}
        />
        {brandRouteOption === BrandRouteOptions.Brandfetch ? (
          <>
            <BrandSearch
              setBrandfetchValues={(values) => setBrandfetchValues(values)}
              companyParams={companyParams}
            />
            {brandfetchValues?.colors && (
              <BrandfetchSwatchGroup
                handleClick={setAccentColor}
                brandfetchColors={brandfetchValues?.colors}
                color={accentColor}
              />
            )}
            {brandfetchValues?.logos && (
              <BrandfetchLogoGroup
                logoURI={logoURI}
                setLogoURI={(uri: string) => setLogoURI(uri)}
                brandfetchLogos={brandfetchValues.logos}
              />
            )}
          </>
        ) : (
          <>
            <ColorPicker
              label="Accent Color:"
              color={accentColor}
              handleChange={setAccentColor}
            />

            <PhotoUpload
              label="Logo:"
              parenthesesText="upload a square logo for best results"
              imageURI={logoURI}
              setImageURI={(uri: string) => setLogoURI(uri)}
              inputId="logo-upload"
              buttonText="Upload Logo"
              replaceButtonText="Replace Logo"
              previewAlt="logo-preview"
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default BrandFetchGroup;
