import { ToggleButtonGroup, ToggleButton, styled } from '@mui/material';
import { BrandRouteOptions } from 'shared/constants';

interface Props {
  handleChange: (value: string) => void;
  value: string;
}

const ToggleGroup = ({ handleChange, value }: Props) => {
  const handleNewValue = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue !== null) {
      handleChange(newValue);
    }
  };
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleNewValue}
      aria-label="Brand Options"
      fullWidth
    >
      <ToggleButtonComponent value={BrandRouteOptions.Brandfetch}>
        Fetch Branding
      </ToggleButtonComponent>
      <ToggleButtonComponent value={BrandRouteOptions.Custom}>
        Enter Yourself
      </ToggleButtonComponent>
    </ToggleButtonGroup>
  );
};

export default ToggleGroup;

const ToggleButtonComponent = styled(ToggleButton)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  textTransform: 'none',
  background: theme.palette.background.altWhite,
  border: `1px solid ${theme.palette.common.black}`,
  color: theme.palette.text.lightGrey,
  borderRadius: `0px ${theme.shape.borderRadius['10']} ${theme.shape.borderRadius['10']} 0px`,

  '&:not(:last-of-type)': {
    borderRadius: `${theme.shape.borderRadius['10']} 0px 0px ${theme.shape.borderRadius['10']}`,
  },

  '&.Mui-selected': {
    background: theme.palette.common.black,
    color: theme.palette.text.white,
    '&:hover': {
      background: theme.palette.common.black,
    },
  },
}));
