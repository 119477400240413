import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Stack,
  SelectChangeEvent,
  Skeleton,
  Typography,
} from '@mui/material';
import { CaretDownIcon } from 'static/icons/CaretDownIcon';

interface Props {
  handleChange: (value: string) => void;
  options: string[];
  label: string;
  value?: string;
}

const DropdownInput = ({ options, label, handleChange, value }: Props) => {
  const handleChangeEvent = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    handleChange(value as string);
  };

  return (
    <Stack>
      {value ? (
        <>
          <InputLabel variant="standard" id={`${label}-id`} shrink={false}>
            {label}:
          </InputLabel>
          <FormControl fullWidth>
            <Select
              labelId={`${label}-id`}
              id={`${label}-select`}
              value={value}
              label={label}
              IconComponent={(props) => <CaretDownIcon {...props} />}
              notched={false}
              onChange={handleChangeEvent}
            >
              {options.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </>
      ) : (
        <>
          <Typography variant="label">
            <Skeleton variant="text" height={17.5} width={80} />
          </Typography>
          <Skeleton variant="rounded" height={65} width="100%" />
        </>
      )}
    </Stack>
  );
};

export default DropdownInput;
