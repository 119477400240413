import { Stack, Skeleton, styled } from '@mui/material';
import { useEnqueueSnackbar } from 'shared/hooks/useEnqueueSnackbar';
import Button from 'shared/components/Button';
import { EditIcon, CopyIcon, DownloadIcon } from 'static/icons';
import { DesignHuddleConfig } from 'shared/types';
import {
  REACT_APP_DESIGN_HUDDLE_BASE_URL,
  REACT_APP_DESIGN_HUDDLE_USER_ACCESS_TOKEN,
} from 'shared/config';
import { useState } from 'react';
import { pollExportJob } from 'shared/utils';

interface Props {
  handleShowModal: () => void;
  handleTextCopy: () => void;
  templateCode?: string;
  internal_project_id?: string;
  config?: DesignHuddleConfig;
  projectPageId: string;
  accessToken?: string;
}

const ButtonGroup = ({
  handleShowModal,
  handleTextCopy,
  templateCode,
  internal_project_id = '',
  config,
  projectPageId,
  accessToken,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const enqueueSnackbar = useEnqueueSnackbar();

  const highResExport = (internal_project_id: string, config: any) => {
    const url =
      REACT_APP_DESIGN_HUDDLE_BASE_URL +
      '/projects/' +
      internal_project_id +
      '/export';
    setIsLoading(true);
    let body;
    let token = REACT_APP_DESIGN_HUDDLE_USER_ACCESS_TOKEN;
    if (accessToken) {
      token = accessToken;
    }
    if (accessToken && projectPageId) {
      body = JSON.stringify({
        format: 'jpg',
        filename: templateCode,
        page_id: projectPageId,
      });
    } else {
      body = JSON.stringify({
        format: 'jpg',
        filename: templateCode,
        ...config,
      });
    }
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      headers: {
        authorization: 'Bearer ' + token,
      },
      body,
    }).then((response) => {
      response.json().then((json) => {
        if (response.ok && json.success && json.data) {
          pollExportJob({
            internal_project_id,
            job_id: json.data.job_id,
            token,
            attempt: 1,
            setIsLoading,
            snackBar: enqueueSnackbar,
          });
        } else {
          setIsLoading(false);
          enqueueSnackbar('Failed to download image', {
            key: 'image-export-error',
            variant: 'error',
          });
          throw new Error('Export Error');
        }
      });
    });
  };

  return (
    <ButtonContainer
      direction="row"
      spacing="12px"
      data-name="preview-button-container"
    >
      {templateCode ? (
        <>
          <Button
            text="Copy Text"
            variant="purple"
            startIcon={<CopyIcon />}
            onClick={handleTextCopy}
          />
          <Button
            text="Save Image"
            variant="purple"
            startIcon={<DownloadIcon />}
            onClick={() => {
              highResExport(internal_project_id, config);
            }}
            loading={isLoading}
          />
          <Button
            text="Edit Image"
            variant="blue"
            startIcon={<EditIcon />}
            onClick={() => handleShowModal()}
          />
        </>
      ) : (
        <>
          <Skeleton variant="rounded" height={46} />
          <Skeleton variant="rounded" height={46} />
          <Skeleton variant="rounded" height={46} />
        </>
      )}
    </ButtonContainer>
  );
};

export default ButtonGroup;

const ButtonContainer = styled(Stack)(({ theme }) => ({
  '& > *': {
    flex: '1 0 33%',
  },
  [theme.breakpoints.down('lg')]: {
    backgroundColor: theme.palette.background.default,
    width: '100vw',
    marginLeft: '-39px',
    marginRight: '-41px',
    padding: '22px 19px 27px 20px',
    justifyContent: 'center',
  },
}));
