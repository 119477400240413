import { Typography, Stack } from '@mui/material';

interface Props {
  title: string;
  content: string;
}

export default function StepContent({ title, content }: Props) {
  return (
    <Stack mb="12px" textAlign="center">
      <Typography variant="tutorialTitle">{title}</Typography>
      <Typography variant="tutorialText">{content}</Typography>
    </Stack>
  );
}
