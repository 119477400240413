import { colors } from '../colors';

export const palette = {
  common: {
    black: colors.black,
    white: colors.white,
  },
  success: {
    main: colors.toastGreen,
  },
  text: {
    black: colors.black,
    white: colors.white,
    darkGrey: colors.textDarkGrey,
    grey: colors.textGrey,
    pink: colors.pink,
    lightGrey: colors.textLightGrey,
  },
  background: {
    default: colors.backgroundDarkBlue, // default used for the background color of the page
    altWhite: colors.backgroundWhite,
    white: colors.white,
    blueGrey: colors.backgroundBlueGrey,
  },
  logo: {
    text: colors.logoText,
  },
  tutorialNavigation: {
    dot: colors.grey,
    dotActive: colors.blue,
    buttonPrev: colors.grey,
    buttonNext: colors.black,
  },
};
