import {
  Button as ButtonComponent,
  ButtonProps,
  CircularProgress,
  useTheme,
} from '@mui/material';
interface Props extends ButtonProps {
  text: string;
  download?: any;
  loading?: boolean;
}

const Button = ({
  text,
  variant,
  type,
  startIcon,
  href,
  onClick,
  download,
  loading = false,
}: Props) => {
  const theme = useTheme();
  return (
    <ButtonComponent
      onClick={onClick}
      startIcon={!loading && startIcon}
      variant={variant}
      type={type}
      href={href}
      {...(download ? { download } : {})}
      sx={{
        '& .MuiButton-startIcon': {
          [theme.breakpoints.down('lg')]: { marginRight: '0px' },
        },
      }}
    >
      {loading ? <CircularProgress size={34} color="inherit" /> : text}
    </ButtonComponent>
  );
};

export default Button;
