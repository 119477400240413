import { useEffect, useState } from 'react';
import {
  Stack,
  Skeleton,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEnqueueSnackbar } from 'shared/hooks/useEnqueueSnackbar';

interface Props {
  imagePreview?: string;
  isLoading: boolean;
}

const PreviewImage = ({ imagePreview, isLoading }: Props) => {
  const [previewImageExists, setPreviewImageExist] = useState(true);

  const theme = useTheme();
  const enqueueSnackbar = useEnqueueSnackbar();

  const previewImageDimensions = useMediaQuery(theme.breakpoints.up('xl'))
    ? 336
    : 300;

  useEffect(() => {
    setPreviewImageExist(true);
  }, [imagePreview]);

  const loader = (
    <Skeleton
      sx={{ m: '0 auto' }}
      variant="rectangular"
      width={previewImageDimensions}
      height={previewImageDimensions}
    />
  );
  return (
    <PreviewImageContainer>
      {imagePreview && previewImageExists && !isLoading ? (
        <Image
          onErrorCapture={() => {
            setPreviewImageExist(false);
            enqueueSnackbar(
              `An error occurred while load that image.
              Please try again later or choose a different image.`,
              {
                key: 'image-404-error',
                variant: 'error',
              },
            );
          }}
          src={imagePreview}
        />
      ) : (
        loader
      )}
    </PreviewImageContainer>
  );
};

export default PreviewImage;

const PreviewImageContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  marginBottom: 21,

  [theme.breakpoints.up('xl')]: {
    marginBottom: 24,
  },
}));

const Image = styled('img')(({ theme }) => ({
  position: 'static',
  display: 'inline-block',
  width: 300,
  minHeight: 300,
  margin: '0 auto',
  [theme.breakpoints.up('xl')]: {
    width: 336,
    minHeight: 336,
  },
}));
