import { ReactNode } from 'react';
import {
  Modal as MuiModal,
  Stack,
  styled,
  Button,
  Typography,
  useTheme,
  SxProps,
} from '@mui/material';
import { CloseIcon } from 'static/icons';

interface Props {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  modalTitle: string;
  modalContentStyles: SxProps;
  modalTitleStyles?: SxProps;
}

export default function Modal({
  children,
  open,
  onClose,
  modalTitle,
  modalContentStyles,
  modalTitleStyles = {},
}: Props) {
  const theme = useTheme();

  return (
    <ModalComponent aria-labelledby="modal-title" open={open} onClose={onClose}>
      <ModalContent sx={modalContentStyles}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            id="modal-title"
            color={theme.palette.text.black}
            sx={modalTitleStyles}
          >
            {modalTitle}
          </Typography>
          <CloseButton
            className="close-button"
            variant="close"
            onClick={onClose}
          >
            <CloseIcon />
          </CloseButton>
        </Stack>
        <MainContent>{children}</MainContent>
      </ModalContent>
    </ModalComponent>
  );
}

const ModalComponent = styled(MuiModal)({
  display: 'flex',
  alignItems: 'center',
});

const ModalContent = styled(Stack)(({ theme }) => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'fixed',
  background: theme.palette.background.white,
  borderRadius: theme.shape.borderRadius[10],
  [theme.breakpoints.down('lg')]: {
    borderRadius: 25,
  },
}));

const MainContent = styled(Stack)({
  height: '100%',
  width: '100%',
});

const CloseButton = styled(Button)(({ theme }) => ({
  width: 21,
  minWidth: 'none',
  height: 21,
  padding: 0,
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  zIndex: theme.zIndex.modal,
}));
