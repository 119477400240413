export const colors = {
  white: '#ffffff',
  black: '#000000',
  offBlack: '#0d0e10',
  tutorialBlack: '#2B2B2B',
  transparent: 'transparent',
  pink: '#da007d',
  blue: '#0083CE',
  buttonBlue: '#2c99d7',
  buttonPurple: '#814b9d',
  buttonBlack: '#141B27',
  purple: '#5048e5',
  backgroundDarkBlue: '#141d2d',
  backgroundBlueGrey: '#515c72',
  backgroundWhite: '#f9fafc',
  textDarkGrey: '#2b2b2b',
  textGrey: '#40454f',
  textLightGrey: '#616161',
  borderGrey: '#cfcfcf',
  toastGreen: '#00a91b',
  logoText: '#231f20',
  accentGrey: '#cecece',
  grey: '#D9D9D9',
  // action colors
  buttonPurpleHover: 'hsl(280, 35%, 42%)',
  buttonPinkHover: 'hsl(326, 100%, 40%)',
  buttonBlueHover: 'hsl(202, 68%, 48%)',
  buttonBlackHover: 'hsl(218, 32%, 20%)',
  defaultAccentColor: '#c985a5',
};
