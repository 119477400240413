export const CheckCircleOutlineIcon: React.FC<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" {...props}>
      <path
        d="M18.5 9c0 4.67-4.004 8.5-9 8.5S.5 13.67.5 9 4.504.5 9.5.5s9 3.83 9 8.5z"
        stroke="#fff"
      />
      <path
        d="M8.654 13a.59.59 0 0 1-.248-.056.625.625 0 0 1-.207-.157l-3.03-3.434A.69.69 0 0 1 5 8.877a.685.685 0 0 1 .197-.464.603.603 0 0 1 .447-.18.608.608 0 0 1 .435.21l2.569 2.916 5.242-6.11a.634.634 0 0 1 .195-.172.595.595 0 0 1 .71.095c.063.06.114.134.149.217a.701.701 0 0 1-.13.75L9.114 12.78a.627.627 0 0 1-.206.16.591.591 0 0 1-.249.059h-.006z"
        fill="#fff"
      />
    </svg>
  );
};
