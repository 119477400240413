import { Stack } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import DropdownInput from 'shared/components/DropdownInput';
import { IMAGE_STYLES, TEXT_TONES } from 'shared/constants';

export interface SelectCommunicationProps {
  eventType?: string;
  eventTypes?: string[];
  setImageStyle: Dispatch<SetStateAction<string>>;
  setTextTone: Dispatch<SetStateAction<string>>;
  imageStyle: string;
  textTone: string;
  handleEventChange: (value: string) => void;
  variantRange: number;
  isEntryReady: boolean;
}

const SelectCommunicationGroup = ({
  eventType,
  eventTypes,
  setImageStyle,
  setTextTone,
  imageStyle,
  textTone,
  handleEventChange,
  variantRange,
  isEntryReady,
}: SelectCommunicationProps) => {
  const filteredImageStyles = IMAGE_STYLES.filter(
    (style) => style.order <= variantRange,
  ).map((item) => item.style);

  useEffect(() => {
    if (!filteredImageStyles.includes(imageStyle) && isEntryReady) {
      setImageStyle(filteredImageStyles[0]);
    }
  }, [variantRange]);

  return (
    <Stack spacing="14px">
      <DropdownInput
        handleChange={(value) => handleEventChange(value)}
        label="Event"
        options={eventTypes || []}
        value={eventType}
      />
      <DropdownInput
        handleChange={setImageStyle}
        label="Image Style"
        options={filteredImageStyles}
        value={imageStyle}
      />
      <DropdownInput
        handleChange={setTextTone}
        label="Text Tone"
        options={Object.values(TEXT_TONES)}
        value={textTone}
      />
    </Stack>
  );
};

export default SelectCommunicationGroup;
