import { FieldSet } from 'airtable';
import { CSSProperties } from 'react';

export interface ImageProps {
  color?: string;
  height?: number | string;
  width?: number | string;
}

export interface AirtableEntry extends FieldSet {
  Casual: string;
  'Casual Read Time': number;
  'Casual Word Count': string;
  'Color HEX': string[];
  'DesignHuddle Template ID': string;
  Direct: string;
  'Direct Read Time': number;
  'Direct Word Count': string;
  'Image Header': string;
  'Image Subheader': string;
  'Moment Title': string;
  Professional: string;
  'Professional Read Time': number;
  'Professional Word Count': string;
  variants: number;
}

export interface BrandfetchSearchResult {
  claimed: boolean;
  name?: string;
  icon: string;
  domain: string;
}

export interface BrandfetchSelectionResult {
  colors: BrandfetchColor[];
  logos: BrandfetchLogo[];
  domain: string;
  name?: string;
}

export interface BrandfetchColor {
  hex: string;
  type: string;
  brightness: number;
}

export interface BrandfetchLogo {
  formats: [
    {
      background: string;
      format: string;
      src: string;
    },
  ];
  theme: string;
  type: string;
}

export interface ImageStylePageNumbers {
  [key: string]: number;
}

export interface PageNumbersImageStyle {
  [key: number]: string;
}

export interface DesignHuddleConfig {
  templateCode: string;
  accentColor: string;
  logo?: string;
  imageStyle?: string;
  width?: number;
  profile?: string;
  employee?: string;
  pageId?: string;
}

export interface DesignHuddleProject {
  current_page_id: string;
  media_type: string;
  project_id: string;
  project_title: string;
  pages: {
    background_color: string;
    page_id: string;
    dimensions: {
      width: number;
      height: number;
      unit_type_id: string;
    };
    // The value in `elements` differs depending on the element
    elements: Record<string, any>;
  }[];
}

export enum DesignHuddleElements {
  Header = 'header',
  Subheader = 'subheader',
  Photo = 'photo',
  Illustration = 'illustration',
  AccentColor = 'accentcolor',
  Logo = 'logo',
  Profile = 'profile',
  Employee = 'employee',
}

export interface ToggleBorderRadiuses {
  [key: number]: {
    [key: number]: CSSProperties['borderRadius'];
  };
}

export interface MappedLogos {
  [key: string]: string;
}

export interface Page {
  page_id: string;
  page_number: number;
  thumbnail_url: string;
}
