import { useState } from 'react';
import { Grid, Stack, styled, useTheme } from '@mui/material';
import { DesignPreview } from 'shared/components/designPreview/DesignPreview';
import MobileHeader from 'shared/components/MobileHeader';
import MobileEditButton from 'shared/components/MobileEditButton';
import MessageBuilderModal from './components/MessageBuilderModal';
import { MessageBuilderProps } from './components/MessageBuilderModal';
import { AirtableEntry } from 'shared/types';
interface Props
  extends Omit<MessageBuilderProps, 'isModalOpen' | 'closeModal'> {
  currentEntry: AirtableEntry | undefined;
  accentColor: string;
  textTone: string;
  textContent: string;
  logoURI: string;
  hideNav?: boolean;
  isEntryReady: boolean;
}

const MobileHomePage = ({
  currentEntry,
  accentColor,
  employeeImageURI,
  textTone,
  textContent,
  imageStyle,
  logoURI,
  employeeName,
  setRandomOptions,
  eventType,
  eventTypes,
  setImageStyle,
  setTextTone,
  handleEventChange,
  setEmployeeName,
  setEmployeeImageURI,
  brandRouteOption,
  setBrandRouteOption,
  setBrandfetchValues,
  brandfetchValues,
  setAccentColor,
  setLogoURI,
  variantRange,
  hideNav,
  isEntryReady,
}: Props) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  return (
    <ScreenStack>
      {!hideNav && <MobileHeader />}

      <MobileEditButton openModal={() => setIsModalOpen(true)} />
      <Grid item xs={7} height="100%">
        <Stack
          bgcolor={theme.palette.background.altWhite}
          p="18px 43px 0px 41px"
          height="100%"
        >
          <DesignPreview
            entry={currentEntry}
            accentColor={accentColor}
            textTone={textTone}
            textContent={textContent}
            imageStyle={imageStyle}
            logo={logoURI}
            employee={employeeName}
            profile={employeeImageURI}
          />
        </Stack>
      </Grid>
      <MessageBuilderModal
        closeModal={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
        setRandomOptions={setRandomOptions}
        eventType={eventType}
        eventTypes={eventTypes}
        setImageStyle={setImageStyle}
        setTextTone={setTextTone}
        imageStyle={imageStyle}
        textTone={textTone}
        handleEventChange={handleEventChange}
        setEmployeeName={setEmployeeName}
        setEmployeeImageURI={setEmployeeImageURI}
        employeeName={employeeName}
        employeeImageURI={employeeImageURI}
        accentColor={accentColor}
        logoURI={logoURI}
        brandRouteOption={brandRouteOption}
        setBrandRouteOption={setBrandRouteOption}
        setBrandfetchValues={setBrandfetchValues}
        brandfetchValues={brandfetchValues}
        setAccentColor={setAccentColor}
        setLogoURI={setLogoURI}
        variantRange={variantRange}
        isEntryReady={isEntryReady}
      />
    </ScreenStack>
  );
};

export default MobileHomePage;

const ScreenStack = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  alignItems: 'center',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));
