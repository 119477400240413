import { InputLabel, Stack, TextField } from '@mui/material';

interface Props {
  handleChange: (value: string) => void;
  label: string;
  value?: string;
  placeholder?: string;
}

const Input = ({ label, handleChange, value, placeholder }: Props) => {
  const handleChangeEvent = (event: any) => {
    const {
      target: { value },
    } = event;
    handleChange(value as string);
  };

  return (
    <Stack>
      <>
        <InputLabel variant="standard" id={`${label}-id`} shrink={false}>
          {label}:
        </InputLabel>
        <TextField
          value={value}
          onChange={(e) => handleChangeEvent(e)}
          placeholder={placeholder}
        />
      </>
    </Stack>
  );
};

export default Input;
