import { FieldSet } from 'airtable';
import {
  AirtableEntry,
  DesignHuddleConfig,
  DesignHuddleElements,
} from './types';
import { IMAGE_STYLE_PAGE_NUMBERS, TOGGLE_BORDER_RADIUSES } from './constants';
import { dshdApi } from 'services/Axios';
import {
  REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_DESIGN_HUDDLE_BASE_URL,
} from './config';
import { Dispatch, SetStateAction } from 'react';

export const mapAirtableTypes = (
  airTableEntries: FieldSet[],
): AirtableEntry[] => {
  return airTableEntries.map((entry) => {
    return {
      Casual: entry.Casual as string,
      'Casual Read Time': entry['Casual Read Time'] as number,
      'Casual Word Count': entry['Casual Word Count'] as string,
      'Color HEX': entry['Color HEX'] as string[],
      'DesignHuddle Template ID': entry['DesignHuddle Template ID'] as string,
      Direct: entry.Direct as string,
      'Direct Read Time': entry['Direct Read Time'] as number,
      'Direct Word Count': entry['Direct Word Count'] as string,
      'Image Header': entry['Image Header'] as string,
      'Image Subheader': entry['Image Subheader'] as string,
      'Moment Title': entry['Moment Title'] as string,
      Professional: entry.Professional as string,
      'Professional Read Time': entry['Professional Read Time'] as number,
      'Professional Word Count': entry['Professional Word Count'] as string,
      variants: entry['variants'] as number,
    };
  });
};

export const createDesignHuddleConfig = ({
  templateCode,
  accentColor,
  logo,
  imageStyle,
  width,
  profile,
  employee,
  pageId,
}: DesignHuddleConfig) => {
  return {
    template_code: templateCode,
    ...(width && { width }),
    ...(imageStyle && {
      page_number: IMAGE_STYLE_PAGE_NUMBERS[imageStyle],
      ...(pageId && { page_id: pageId }),
    }),
    customizations: {
      classes: {
        [DesignHuddleElements.AccentColor]: {
          color: accentColor,
        },
        ...(employee && {
          [DesignHuddleElements.Employee]: {
            text: employee,
            auto_fit: true,
          },
        }),
        ...(profile && {
          [DesignHuddleElements.Profile]: {
            masked_media: {
              url: profile,
            },
          },
        }),
        ...(logo && {
          [DesignHuddleElements.Logo]: {
            url: logo,
          },
        }),
      },
    },
  };
};

export const getBorderRadius = (numberOfLogos: number, position: number) => {
  const logoAmtObj = TOGGLE_BORDER_RADIUSES[numberOfLogos];

  return position in logoAmtObj ? logoAmtObj[position] : 0;
};

export const uploadCloudinaryImage = async (imageFile: File) => {
  const body = new FormData();
  body.append('file', imageFile);
  body.append('upload_preset', 'oohntneo');

  const img = await fetch(
    `https://api.cloudinary.com/v1_1/${REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
    {
      body,
      method: 'POST',
      mode: 'cors',
    },
  ).then((response) => response.json());
  return img.url;
};

export const uploadImage = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setImageURI: (arg: string) => void,
) => {
  if (event.target.files && event.target.files[0]) {
    const img = await uploadCloudinaryImage(event.target.files[0]);
    setImageURI(img);
  }
};

export const getRelevantTemplates = async (template_code: string) => {
  const response = await dshdApi.get(`/gallery/templates`, {
    params: {
      template_code,
    },
  });
  return response.data;
};

export const getRelevantProject = async (project_id: string) => {
  const response = await dshdApi.get(`/projects/${project_id}`);
  return response.data;
};

export const pollExportJob = ({
  internal_project_id,
  job_id,
  attempt = 1,
  token,
  setIsLoading,
  snackBar,
}: {
  internal_project_id: string;
  job_id: string;
  attempt: number;
  token: string;
  setIsLoading: Dispatch<SetStateAction<any>>;
  snackBar: any;
}) => {
  var url =
    REACT_APP_DESIGN_HUDDLE_BASE_URL +
    '/projects/' +
    internal_project_id +
    '/export/jobs/' +
    job_id;

  fetch(url, {
    method: 'GET',
    mode: 'cors',
    credentials: 'omit',
    headers: {
      authorization: 'Bearer ' + token,
    },
  }).then((response) => {
    response.json().then((json) => {
      if (
        !response.ok ||
        !json.success ||
        !json.data ||
        (!json.data.completed && attempt < 10)
      ) {
        setTimeout(() => {
          pollExportJob({
            internal_project_id,
            job_id,
            attempt: attempt + 1,
            token,
            setIsLoading,
            snackBar,
          });
        }, attempt * 1000);
      } else {
        if (json.data.completed && json.data.download_url) {
          pollExportSuccess(json.data.download_url, setIsLoading, snackBar);
        } else {
          setIsLoading(false);
          snackBar('Failed to download image', {
            key: 'image-download-error',
            variant: 'error',
          });
        }
      }
    });
  });
};

const pollExportSuccess = async (
  imgUrl: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  snackBar: (
    arg1: string,
    { key, variant }: { key: string; variant: string },
  ) => void,
) => {
  const link = document.createElement('a');
  link.href = imgUrl;
  link.download = 'image.png';
  link.click();
  link.remove();
  snackBar('Image has been downloaded', {
    key: 'image-download-success',
    variant: 'success',
  });
  setIsLoading(false);
  return;
};
