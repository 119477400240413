import { useSnackbar as useDefaultSnackbar, OptionsObject } from 'notistack';
import ToastNotification from 'shared/components/ToastNotification';

export const useEnqueueSnackbar = () => {
  const { enqueueSnackbar } = useDefaultSnackbar();

  const pushSnackbar = (
    message: string,
    // extend the default options object
    options?: OptionsObject &
      Partial<{ variant: 'success' | 'error' | 'warning' }>,
  ) => {
    enqueueSnackbar(message, {
      ...options,
      content: () => {
        // destructure the options we need from the extended options
        // object, and provide a default case if we didn't provide any
        const { variant, key } = options || { variant: undefined };
        return (
          <ToastNotification
            id={`${key}`}
            message={message}
            severity={variant || 'success'}
          />
        );
      },
    });
  };

  return pushSnackbar;
};
