import styled from '@emotion/styled';
import { IconButton, Typography } from '@mui/material';
import { colors } from 'shared/styles/colors';
import { RandomIcon } from 'static/icons';

interface Props {
  handleClick: () => void;
  showText?: boolean;
}

const RandomButton = ({ handleClick, showText = false }: Props) => {
  return (
    <IconButton aria-label="randomize" onClick={handleClick}>
      <RandomIcon />
      {showText && <Text>Randomize</Text>}
    </IconButton>
  );
};

export default RandomButton;

const Text = styled(Typography)({
  color: colors.purple,
  marginLeft: 9,
});
